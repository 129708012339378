export default {
  "navbar.home": "Home",
  "navbar.institucional": "Institucional",
  "navbar.servicos": "Serviços",
  "navbar.utilitarios": "Utilitários",
  "navbar.contato": "Contato",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "newsletter.message.success": "Você assinou nossa Newsletter com sucesso.",
  "newsletter.message.error": "Ocorreu um erro ao assinar a nossa Newsletter, por favor tente novamente.",
  "newsletter.title": "Assine nossa Newsletter",
  "newsletter.subtitle":
    "Fique por dentro do que fazemos, receba no seu e-mail novidades sobre as ferramentas e processos que utilizamos no nosso dia a dia, livre de spam e propagandas!",
  "newsletter.placeholder.name": "Como você se chama?",
  "newsletter.placeholder.email": "Qual seu e-mail?",
  "newsletter.placeholder.button": "Enviar",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "footer.endereco":
    "Av. Sete de Setembro, nº 776, 5º andar, sala 501, Ed. 7 Eco Office, CEP 88.301-200, Centro -Itajaí/SC - Telefone e Whatsapp: +55 (47) 98868-8772",
  "footer.endereco.title": "Endereço: ",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "homepage.carousel.title1": "Importação",
  "homepage.carousel.subtitle1": "Por Encomenda e Por Conta e Ordem de Terceiros",
  "homepage.carousel.title2": "Assessoria",
  "homepage.carousel.subtitle2": "Assessoria em Comércio Exterior e Logística",
  "homepage.carousel.title3": "Agende Uma Visita",
  "homepage.carousel.subtitle3": "Venha encontrar a assessoria e solução que você precisa",
  "homepage.clock1": "Brasilia",
  "homepage.clock2": "London",
  "homepage.clock3": "Washington",
  "homepage.clock4": "Pequim",
  "homepage.clock5": "Joanesburgo",
  "homepage.card1.title": "Missão",
  "homepage.card1.text": "Atender as necessidades de nossos clientes.",
  "homepage.card2.title": "Valores",
  "homepage.card2.text":
    "Fé, comprometimento, ética, transparência, compaixão, integridade, verdade, respeito, humildade e competência.",
  "homepage.card3.title": "Visão",
  "homepage.card3.text": "Ser referência através da qualidade em nossos serviços.",
  "homepage.cotacao.title": "Cotação do dia",
  "homepage.cotacao.col1": "Real Para",
  "homepage.cotacao.col2": "Multiplicar por",
  "homepage.cotacao.col1.linha1": "Dólar",
  "homepage.cotacao.col1.linha2": "Euro",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "institucional.title": "Fluctus Group",
  "institucional.card1.title": "Fluctus Trading",
  "institucional.card1.subtitle": "Divisão de comercio exterior",
  "institucional.card1.text": "É uma empresa focada na assessoria e consultoria em comércio exterior.",
  "institucional.card2.title": "Fluctus Supplier Search",
  "institucional.card2.subtitle": "Divisão de busca de fornecedores",
  "institucional.card2.text":
    "É especializada na busca por fornecedores fora do país, no acompanhamento e assessoria de clientes que necessitam de auxilio e atendimento personalizado.",
  "institucional.card3.title": "Fluctus 3D Print",
  "institucional.card3.subtitle": "Divisão de tributos",
  "institucional.card3.text":
    "É uma divisão especializada em assessoria tributária, que busca oferecer ao cliente, sempre o caminho mais econômico, eficiente e correto para o sucesso de sua empresa.",
  "institucional.card4.title": "Fluctus Log",
  "institucional.card4.subtitle": "Divisão de logística",
  "institucional.card4.text":
    "É uma divisão focada no armazenamento e distribuição de produtos para atender seus clientes em todo o território nacional.",
  "institucional.card5.title": "Fluctus Store",
  "institucional.card5.subtitle": "Divisão de comércio",
  "institucional.card5.text":
    "É uma loja virtual, focada no comercio de produtos nacionais e importados, que possui uma equipe qualificada para atender seus clientes em todo o território nacional.",
  "institucional.card6.title": "Fluctus Relax",
  "institucional.card6.subtitle": "Divisão de massagem express",
  "institucional.card6.text":
    "É uma divisão especializada na administração de equipamentos de massagem rápidas, que estão distribuídos em diversas partes do país.",
  "institucional.card7.title": "Fluctus 3D Print",
  "institucional.card7.subtitle": "Divisão de produtos 3D personalizados",
  "institucional.card7.text": "É uma divisão especializada em produção de produtos feitos em impressora em 3D, proporcionando aos seus clientes satisfação e agilidade em ter o produto desejado, de forma econômica, eficiente e personalizada.",
  "institucional.card.button": "Ver mais",
  "institucional.section.title": "EMPRESA",
  "institucional.section.text":
    "O Fluctus Group, é composto por cinco divisões que atuam em segmentos diferentes, mas interligadas, que são reconhecidas no mercado pela alta credibilidade e transparência de seus serviços prestados. Apoia empresas de diversos segmentos, desde consultorias para processos de importação e exportação até a prestações de serviços ligadas ao mundo corporativo do Comércio Exterior.",
  "institucional.section.text2":
    "Buscamos sempre apresentar agilidade, eficiência e objetividade, que são fatores fundamentais em cada ponto das assessorias desenvolvidas. Nosso principal objetivo é reduzir ao máximo possível os custos, nos âmbitos fiscais, administrativos, comerciais ou logísticos.",
  "institucional.section2.title": "META",
  "institucional.section2.text":
    "Nossa meta é atender todos os clientes de imediato e de forma personalizada, proporcionando assim mais comodidade e satisfação. Trabalhamos com foco e compromisso no cumprimento das normas vigentes, de diversos setores, buscando incansavelmente evitar entraves e apresentar com excelência as melhores soluções para nossos clientes.",
  "institucional.section3.title": "FLUCTUS",
  "institucional.section3.text":
    "A palavra Fluctus é originária do Latim, que significa “Terreno coberto pelo fluxo de líquido, usado em Vênus, Io, Titã e Marte” também conhecido como “Onda” em português.",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "servicos.title": "O que Fazemos",
  "servicos.list.trading.title": "Fluctus Trading",
  "servicos.list.trading.item1": "Previsão de custo de importação e exportação",
  "servicos.list.trading.item2": "Importação por conta e ordem de terceiros",
  "servicos.list.trading.item3": "Importação por encomenda",
  "servicos.list.trading.item4": "Financiamento de importações e exportações com recursos próprios",
  "servicos.list.trading.item5": "Exportação",
  "servicos.list.trading.item6": "Regimes especiais ou atípicos (Admissão temporárias e entreposto aduaneiro)",
  "servicos.list.trading.item7":
    "Terceirização das atividades relacionadas a Comércio Exterior, gerando redução de custo e oferecendo maior eficiência ao cliente",
  "servicos.list.trading.item8": "Emissão e controle de licenças de importação",
  "servicos.list.trading.item9": "Análise documental prévia ao embarque",
  "servicos.list.trading.item10": "Análise de rótulos e etiquetas",
  "servicos.list.trading.item11": "Nacionalização em fronteiras terrestres de mercadorias oriundas do MERCOSUL",
  "servicos.list.trading.item12": "Seguro porta-a-porta com tarifa especial",
  "servicos.list.trading.item13": "Adequação do produto para ser importado",
  "servicos.list.trading.item14": "Despacho Aduaneiro",
  "servicos.list.trading.item15": "Registro no MAPA",
  "servicos.list.trading.item16": "Registro na ANVISA",
  "servicos.list.trading.item17": "Registro no INMETRO",
  "servicos.list.trading.item18": "Registro na ANATEL",
  "servicos.list.trading.item19": "Registro no SISPROM",
  "servicos.list.trading.item20": "Registro no SISCOSERV",
  "servicos.list.trading.item21": "Registro de bebidas frias e quentes",
  "servicos.list.trading.item22": "Acompanhamento do embarque desde a coleta até a entrega no destino final",

  "servicos.list.supplier.title": "Fluctus Supplier Search",
  "servicos.list.supplier.item1": "Busca de fornecedores",
  "servicos.list.supplier.item2": "Acompanhamento de clientes em viagens internas e internacionais",
  "servicos.list.supplier.item3": "Auxílio na escolha de fornecedores dentro e fora do País",
  "servicos.list.supplier.item4":
    "Suporte fora do país acompanhado por profissionais residentes e conhecedores do ambiente",

  "servicos.list.tax.title": "Fluctus 3D Print",
  "servicos.list.tax.item1": "Assessoria técnico tributária",
  "servicos.list.tax.item2": "Ex-Tarifário",
  "servicos.list.tax.item3": "Drawback",

  "servicos.list.log.title": "Fluctus Log",
  "servicos.list.log.item1": "Agenciamento de carga",
  "servicos.list.log.item2": "Fretes internacionais e nacionais com valor competitivo e free time diferenciado",
  "servicos.list.log.item3": "Transportes (Marítimo, Aéreo, Rodoviário, Ferroviário)",
  "servicos.list.log.item4": "Transporte rodoviário de cargas fracionadas",
  "servicos.list.log.item5": "Acompanhamento sobre o armazenamento de cargas (em recinto Alfandegado e Armazém geral)",
  "servicos.list.log.item6": "Serviços de separação e distribuição",

  "servicos.list.commerce.title": "Fluctus Store",
  "servicos.list.commerce.item1":
    "Comercialização de mercadorias nacionais e importados através de comercio eletrônico",
  "servicos.list.commerce.item2": "Auxílio na venda de seu produto",

  "servicos.list.relax.title": "Fluctus Relax",
  "servicos.list.relax.item1": "Administração de equipamentos de massagens rápidas",
  "servicos.list.relax.item2": "Atendimento personalizado em ambientes corporativos",

  "servicos.list.events.title": "Fluctus Events",
  "servicos.list.events.item1": "Organização de eventos corporativos",
  "servicos.list.events.item2": "Estruturas (Box em madeira e alumínio)",
  "servicos.list.events.item3": "Design gráfico, criação e edição de apresentações, edição de vídeos e fotos",
  "servicos.list.events.item4": "Produção Multimídia",
  "servicos.list.events.item5": "Recepcionistas e Promotores(as)",
  "servicos.list.events.item6": "Tradutores profissionais multilíngues",
  "servicos.list.events.item7": "Iluminação",
  "servicos.list.events.item8": "Sonorização",
  "servicos.list.events.item9": "Projetores de alta definição, telas de projeção",

  "servicos.paragraph":
    "Fé, comprometimento, ética, transparência, compaixão, integridade, verdade, respeito, humildade e competência.",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "utilitarios.title": "Utilitarios",

  //accordion conversao
  "utilitarios.accordion.conversao.name": "FATORES DE CONVERSÃO",
  "utilitarios.accordion.conversao.table1.title": "Comprimento",
  "utilitarios.accordion.conversao.table1.col1": "Conversão",
  "utilitarios.accordion.conversao.table1.col2": "Multiplicar por",
  "utilitarios.accordion.conversao.table1.item1": "Polegadas para Centímetros",
  "utilitarios.accordion.conversao.table1.item2": "Centímetros para Polegadas",
  "utilitarios.accordion.conversao.table1.item3": "Polegadas para Milímetros",
  "utilitarios.accordion.conversao.table1.item4": "Milímetros para Polegadas",
  "utilitarios.accordion.conversao.table1.item5": "Pés para Metros",
  "utilitarios.accordion.conversao.table1.item6": "Metros para Pés",
  "utilitarios.accordion.conversao.table1.item7": "Milhas para Quilômetros",
  "utilitarios.accordion.conversao.table1.item8": "Quilômetros para Milhas",
  "utilitarios.accordion.conversao.table1.item9": "CONVERSÃO",

  "utilitarios.accordion.conversao.table2.title": "Volume",
  "utilitarios.accordion.conversao.table2.item1": "Polegadas Cúbicas para Centímetros Cúbicos",
  "utilitarios.accordion.conversao.table2.item2": "Centímetros Cúbicos para Polegadas Cúbicas",
  "utilitarios.accordion.conversao.table2.item3": "Pés Cúbicos para Metros Cúbicos",
  "utilitarios.accordion.conversao.table2.item4": "Metros Cúbicos para Pés Cúbicos",
  "utilitarios.accordion.conversao.table2.item5": "Galões Americanos para Litros",
  "utilitarios.accordion.conversao.table2.item6": "Litros para Galões Americanos",

  "utilitarios.accordion.conversao.table3.title": "Peso",
  "utilitarios.accordion.conversao.table3.item1": "Libras para Quilogramas",
  "utilitarios.accordion.conversao.table3.item2": "Quilogramas para Libras",
  "utilitarios.accordion.conversao.table3.item3": "Toneladas Longas para Toneladas Métricas",
  "utilitarios.accordion.conversao.table3.item4": "Toneladas Métricas para Toneladas",
  "utilitarios.accordion.conversao.table3.item5": "Toneladas Curtas para Toneladas Métricas",
  "utilitarios.accordion.conversao.table3.item6": "Toneladas Métricas para Toneladas Curtas",

  "utilitarios.accordion.conversao.table4.title": "Temperatura",
  "utilitarios.accordion.conversao.table4.col2": "Fórmula",
  "utilitarios.accordion.conversao.table4.item1": "Graus Centígrados para Fahrenheit",
  "utilitarios.accordion.conversao.table4.item2": "Quilogramas para Libras",
  "utilitarios.accordion.conversao.table4.item3": "multiplicar por 1,8 e adicionar 32",
  "utilitarios.accordion.conversao.table4.item4": "subtrair 32 e multiplicar por 0,56",

  //accordion containers
  "utilitarios.accordion.containers.name": "TIPOS DE CONTAINERS",
  "utilitarios.accordion.containers.table.col1": "Dimensões",
  "utilitarios.accordion.containers.table.col2": "Largura (m)",
  "utilitarios.accordion.containers.table.col3": "Comprimento (m)",
  "utilitarios.accordion.containers.table.col4": "Altura (m)",
  "utilitarios.accordion.containers.table.linha1": "Externa",
  "utilitarios.accordion.containers.table.linha2": "Interna",
  "utilitarios.accordion.containers.table.linha3": "Porta",
  "utilitarios.accordion.containers.table2.col1": "Cap. Cúbica (m3)",
  "utilitarios.accordion.containers.table2.col2": "Cap. Carga (ton)",
  "utilitarios.accordion.containers.table2.col3": "Tara (ton)",

  //accordion calculo
  "utilitarios.accordion.maritimo.name": "CÁLCULO MARÍTIMO",
  "utilitarios.accordion.maritimo.text": "No transporte marítimo, por convenção 1 tonelada é igual a 1m³. Exemplo:",
  "utilitarios.accordion.maritimo.table.col1": "Tipo",
  "utilitarios.accordion.maritimo.table.col2": "Peso Bruto",
  "utilitarios.accordion.maritimo.table.col3": "Cubado",
  "utilitarios.accordion.maritimo.table.col4": "Peso Cubado",
  "utilitarios.accordion.maritimo.table.linha1": "Cilindro",
  "utilitarios.accordion.maritimo.table.linha2": "Caixa",
  "utilitarios.accordion.maritimo.paragraph":
    "* No caso do transporte marítimo, a conta é ainda mais simples. Considera-se que sempre 1 metro cúbico é igual a 1 tonelada. No caso da caixa será cobrada a tarifa mínima, que pode variar entre ½ e 1 tonelada, de acordo com a companhia marítima a ser utilizada. No caso do cilindro o peso taxável será de 2,10 toneladas, embora a carga tenha PESO BRUTO de 1,5 toneladas.",

  //accordion uteis
  "utilitarios.accordion.uteis.name": "LINKS ÚTEIS",
  "utilitarios.accordion.uteis.title1": "Orgãos Federais",
  "utilitarios.accordion.uteis.title2": "Principais Portos Marítimos do Brasil",
  "utilitarios.accordion.uteis.col1.item1": "ABNT – Associação Brasileira de Normas Técnicas",
  "utilitarios.accordion.uteis.col1.item2": "ANTAQ – Agência Nacional de Transporte Aquaviários",
  "utilitarios.accordion.uteis.col1.item3": "Internacionais",
  "utilitarios.accordion.uteis.col1.item4": "ANTT – Agência Nacional de Transportes Terrestres",
  "utilitarios.accordion.uteis.col1.item5": "CNT – Confederação Nacional do Transporte",
  "utilitarios.accordion.uteis.col1.item6": "Governo do Brasil",
  "utilitarios.accordion.uteis.col1.item7": "IBGE – Instituto Brasileiro de Geografia e Estatística",
  "utilitarios.accordion.uteis.col1.item8": "Receita Federal",
  "utilitarios.accordion.uteis.col1.item9": "Ministério do Planejamento",
  "utilitarios.accordion.uteis.col1.item10": "Ministério dos Transportes",
  "utilitarios.accordion.uteis.col1.item11": "Ministério da economia indústria, comércio exterior e serviços",
  "utilitarios.accordion.uteis.col2.item1": "Companhia Docas do Espírito Santo / Porto de Vitória",
  "utilitarios.accordion.uteis.col2.item2": "Porto de Imbituba",
  "utilitarios.accordion.uteis.col2.item3": "Porto de Itajaí",
  "utilitarios.accordion.uteis.col2.item4": "Porto de Itapoá",
  "utilitarios.accordion.uteis.col2.item5": "Porto de Navegantes",
  "utilitarios.accordion.uteis.col2.item6": "Porto de Recife",
  "utilitarios.accordion.uteis.col2.item7": "Porto do Rio Grande",
  "utilitarios.accordion.uteis.col2.item8": "Porto de Suape",
  "utilitarios.accordion.uteis.col2.item9": "Porto de São Francisco do Sul",
  "utilitarios.accordion.uteis.col2.item10": "Porto de Santos",

  //accordion glossario
  "utilitarios.accordion.glossario.name": "GLOSSÁRIO",

  "utilitarios.accordion.glossario.definicao.item1": "Espaço Cúbico Permitido",
  "utilitarios.accordion.glossario.definicao.item2": "Notificação relacionada a algo recebido.",
  "utilitarios.accordion.glossario.definicao.item3":
    "Taxa de seguro cobrada sobre certas tarifas de frete ou alfandegárias proporcionais ao valor total dos produtos da operação (Nota Fiscal).",
  "utilitarios.accordion.glossario.definicao.item4":
    "Regime aduaneiro especial que permite a importação de bens que devam permanecer no País durante prazo fixado, com suspensão total ou parcial de tributos.",
  "utilitarios.accordion.glossario.definicao.item5": "Articles Dangereux de Route ou Transporte de Artigos Perigosos.",
  "utilitarios.accordion.glossario.definicao.item6":
    "Congregação de países que têm como objetivo o estabelecimento de um mercado comum latino-americano, formada por: Argentina, Bolívia, Brasil, Chile, Colômbia, Cuba, Equador, México, Paraguai, Peru, Uruguai e Venezuela.",
  "utilitarios.accordion.glossario.definicao.item7":
    "Congregação de países que têm como objetivo o estabelecimento de um mercado comum latino-americano, formada por: Argentina, Bolívia, Brasil, Chile, Colômbia, Cuba, Equador, México, Paraguai, Peru, Uruguai e Venezuela.",
  "utilitarios.accordion.glossario.definicao.item8":
    "Bens representados por quantidade, fragmentos ou partes, estritamente necessários para dar a conhecer sua natureza, espécie e qualidade.",
  "utilitarios.accordion.glossario.definicao.item9":
    "Lugar coberto, onde os materiais/produtos são recebidos, classificados, estocados e expedidos.",
  "utilitarios.accordion.glossario.definicao.item10": "Air Waybill ou Conhecimento de Transporte Aéreo.",
  "utilitarios.accordion.glossario.definicao.item11": "Conhecimento de embarque",
  "utilitarios.accordion.glossario.definicao.item12":
    "Consolidação de uma única expedição em um MAWB (Master Air Waybill - Conhecimento Principal de Transporte Aéreo) abrangendo um HAWB (House Air Waybill - Guia de Transporte Aéreo emitida por um expedidor).",
  "utilitarios.accordion.glossario.definicao.item13":
    "Para efeitos de tratamento tributário são considerados como bagagem os bens novos ou usados, destinados ao uso ou consumo pessoal do viajante, em compatibilidade com as circunstâncias de sua viagem.",
  "utilitarios.accordion.glossario.definicao.item14":
    "Local próximo ao porto, seguro, onde os navios ficam aguardando autorização para atracarem no cais disponível ou determinado",
  "utilitarios.accordion.glossario.definicao.item15":
    "É o conjunto monolítico formado pela carroceria com o conjunto de dois eixos e pelo menos quatro rodas. É engatado na carroceria do caminhão para o transporte, formando um conjunto de duas carrocerias puxadas por um só caminhão.",
  "utilitarios.accordion.glossario.definicao.item16": "Lado esquerdo do navio.",
  "utilitarios.accordion.glossario.definicao.item17": "Armazém Alfandegado.",
  "utilitarios.accordion.glossario.definicao.item18":
    "Expressão do transporte marítimo que significa o transporte de carga geral ou fracionadas.",
  "utilitarios.accordion.glossario.definicao.item19": "Empresas especializadas em intermediar afretamento marítimo.",
  "utilitarios.accordion.glossario.definicao.item20": "Carga à granel, ou seja, sem embalagem.",
  "utilitarios.accordion.glossario.definicao.item21":
    "Navio graneleiro, ou seja, próprio para o transporte de cargas à granel.",
  "utilitarios.accordion.glossario.definicao.item22":
    "Contêiner graneleiro, ou seja, próprio para o transporte de cargas à granel.",
  "utilitarios.accordion.glossario.definicao.item23": "Navegação doméstica (pela costa do País).",
  "utilitarios.accordion.glossario.definicao.item24":
    "Equipamento usado em portos para levantar grandes cargas pesadas ou materiais em obras, e que consta de 3 pontaletes unidos no topo onde recebem uma roldana por onde passa o cabo.",
  "utilitarios.accordion.glossario.definicao.item25":
    "Expressão do transporte marítimo, que significa profundidade em que cada navio está submerso na água. Tecnicamente é a distância da lâmina d’água até a quilha do navio.",
  "utilitarios.accordion.glossario.definicao.item26":
    "É o serviço utilizado geralmente em portos, onde profissionais autônomos executam o trabalho de movimentação de cargas.",
  "utilitarios.accordion.glossario.definicao.item27": "É uma carreta fechada.",
  "utilitarios.accordion.glossario.definicao.item28":
    "É uma carreta fechada, com isolamento térmico em suas paredes, que conserva a temperatura da carga.",
  "utilitarios.accordion.glossario.definicao.item29":
    "É o conjunto monolítico formado pela cabine, motor e rodas de tração do caminhão. Pode ser engatado em vários tipos de carretas e semi-reboques, para o transporte.",
  "utilitarios.accordion.glossario.definicao.item30":
    "Convênio de Pagamentos e Créditos Recíprocos – Sistema de pagamentos controlado pelos bancos centrais que abrange os países da ALADI e a República Dominicana.",
  "utilitarios.accordion.glossario.definicao.item31": "Cost and Freight ou Custo e Frete.",
  "utilitarios.accordion.glossario.definicao.item32": "Comprovante de Importação.",
  "utilitarios.accordion.glossario.definicao.item33":
    "cobrada sobre petróleo e derivados.CIF - Cost, Insurance and Freight ou Custo, Seguro e Frete. Neste caso, o material cotado já tem tudo embutido no preço, ou seja, é posto no destino.",
  "utilitarios.accordion.glossario.definicao.item34": "Cariage and Insurance Paid To ou Transporte e Seguro Pagos Até.",
  "utilitarios.accordion.glossario.definicao.item35":
    "Conjunto de veículos que seguem juntos para um mesmo destino. Utilizado principalmente por motivo de segurança; carros de munições e mantimentos que acompanham forças militares; composição ferroviária (em Portugal).",
  "utilitarios.accordion.glossario.definicao.item36":
    "Documento emitido pela transportadora, baseado nos dados da Nota Fiscal, que informa o valor do frete e acompanha a carga. O destinatário assina o recebimento em uma das vias.",
  "utilitarios.accordion.glossario.definicao.item37":
    "Consignatário. Pessoa física ou jurídica indicada no documento de transporte que tem o direito de reclamar os bens ao transportador, no destino. Para os efeitos legais, presume-se o proprietário da carga.",
  "utilitarios.accordion.glossario.definicao.item38":
    "Consiste em criar grandes carregamentos a partir de vários outros pequenos. Resulta em economia de escala no custo dos fretes.",
  "utilitarios.accordion.glossario.definicao.item39":
    "Equipamento de metal no formato de uma grande caixa, que serve para o transporte de diversos materiais, fazendo assim uma unitização de cargas, que ao estarem acondicionados no seu interior, não sofrem danos durante o percurso e nem em caso de transbordo para outros modais.",
  "utilitarios.accordion.glossario.definicao.item40": "Área da primeira coberta do navio.",
  "utilitarios.accordion.glossario.definicao.item41": "Commerce Planning Colaboration.",
  "utilitarios.accordion.glossario.definicao.item42": "Cariage Paid To ou Transporte Pago Até.",
  "utilitarios.accordion.glossario.definicao.item43":
    "Volume cúbico disponível para estocar ou transportar. Calcula-se o metro cúbico multiplicando-se o comprimento pela largura e pela altura.",
  "utilitarios.accordion.glossario.definicao.item44":
    "É a somatória do custo do transporte, do custo de armazenagem e do custo de manutenção de estoque.",
  "utilitarios.accordion.glossario.definicao.item45":
    "Regime aduaneiro especial que permite o depósito, no mercado interno, sob regime alfandegado, de produtos já comercializados com o exterior, considerados exportados para todos os efeitos fiscais, creditícios e cambiais.",
  "utilitarios.accordion.glossario.definicao.item46": "Delivered At Frontier ou Entregue na Fronteira.",
  "utilitarios.accordion.glossario.definicao.item47": "Delivered Duty Paid ou Entregue com Taxas Pagas.",
  "utilitarios.accordion.glossario.definicao.item48": "Delivered Duty Unpaid ou Entregue sem Taxas Pagas.",
  "utilitarios.accordion.glossario.definicao.item49": "Prazo-limite para entrega da carga para embarque.",
  "utilitarios.accordion.glossario.definicao.item50":
    "Multa determinada em contrato, a ser paga pelo contratante de um navio, quando este demora mais do que o acordado nos portos de embarque ou de descarga. O termo também utilizado para as diárias a serem pagas pelo importador após o período de estadia livre concedido pelo agente de carga/armador.",
  "utilitarios.accordion.glossario.definicao.item51":
    "Delivered Ex QUAY ou entrega no cais. O vendedor entrega a mercadoria no cais do porto de destino.",
  "utilitarios.accordion.glossario.definicao.item52": "Delivered Ex SHIP ou Entrega no Navio.",
  "utilitarios.accordion.glossario.definicao.item53":
    "Documento-base do despacho de importação que deve conter a identificação do importador, a classificação, o valor aduaneiro e a origem da mercadoria, dentre outras informações.",
  "utilitarios.accordion.glossario.definicao.item54":
    "É o local intermediário que as mercadorias ficam entre a expedição e os transportes (vários modais), a fim de facilitar e agilizar a operação de carregamento e descarregamento.",
  "utilitarios.accordion.glossario.definicao.item55":
    "Serviço de escavação nos canais de acesso e áreas de atracação dos portos para manutenção ou aumento da profundidade.",
  "utilitarios.accordion.glossario.definicao.item56":
    "Envolve a importação de componentes, sem pagamento de impostos (IPI, ICMS, Adicional ao Frete para Renovação da Marinha Mercante e Imposto sobre Prestações de Serviços de Transporte Estadual), vinculada a um compromisso de exportação.",
  "utilitarios.accordion.glossario.definicao.item57": "Declaração Simplificada de Exportação.",
  "utilitarios.accordion.glossario.definicao.item58": "Declaração Simplificada de Importação.",
  "utilitarios.accordion.glossario.definicao.item59": "Estação Aduaneira do Interior.",
  "utilitarios.accordion.glossario.definicao.item60":
    "Economic Logistic Quantity ou Quantidade Logística Econômica. É a quantidade que minimiza o custo logístico.",
  "utilitarios.accordion.glossario.definicao.item61":
    "Envoltório apropriado, aplicado diretamente ao produto para sua proteção e preservação até o consumo/utilização final.",
  "utilitarios.accordion.glossario.definicao.item62":
    "Regime especial que permite a importação de mercadoria estrangeira para armaze-namento em recinto alfandegado de uso público, com suspensão do pagamento dos impostos.",
  "utilitarios.accordion.glossario.definicao.item63":
    "Regime especial que permite à empresa importar com suspensão do pagamento dos tributos, sob controle informatizado, mercadorias para serem submetidas à operação de industrialização e posterior exportação.",
  "utilitarios.accordion.glossario.definicao.item64": "Lado direito do navio.",
  "utilitarios.accordion.glossario.definicao.item65":
    "Empregado das Docas que trabalha na carga e descarga dos navios.",
  "utilitarios.accordion.glossario.definicao.item66":
    "Expressão do transporte marítimo, que significa dia da atracação (chegada).",
  "utilitarios.accordion.glossario.definicao.item67":
    "Expressão do transporte marítimo, que significa dia da saída (zarpar).",
  "utilitarios.accordion.glossario.definicao.item68":
    "Saída de produtos do País, por tempo determinado, com suspensão do pagamento de impostos, inclusive o de exportação, condicionada à sua reimportação no estado em que foram exportados.",
  "utilitarios.accordion.glossario.definicao.item69": "Ex Works.",
  "utilitarios.accordion.glossario.definicao.item70":
    "Free Alongside Ship ou Livre no Costado do Navio. O vendedor entrega a mercadoria ao comprador no costado do navio no porto de embarque.",
  "utilitarios.accordion.glossario.definicao.item71":
    "Free Carrier ou Transportador livre. O vendedor está isento de responsabilidades, no momento que entrega a mercadoria para o agente indicado pelo comprador ou para o transportador.",
  "utilitarios.accordion.glossario.definicao.item72": "Full Container Load ou Contêiner Completo.",
  "utilitarios.accordion.glossario.definicao.item73":
    "Forwarder Certificate of Receipt ou Certificado de Recebimento do Agente de Transportes.",
  "utilitarios.accordion.glossario.definicao.item74":
    "Serviço marítimo de alimentação do porto hub ou de distribuição das cargas nele concentradas.",
  "utilitarios.accordion.glossario.definicao.item75": "Navios de abastecimento.",
  "utilitarios.accordion.glossario.definicao.item76":
    "Free In and Out ou isento de taxas no embarque e no desembarque. Despesas de embarque são do exportador e as de desembarque do importador. Nada de responsabilidade do Armador.",
  "utilitarios.accordion.glossario.definicao.item77":
    "Free On Board ou Preço sem Frete Incluso (posto a bordo). Tem algumas variações de FOB. Pode ser FOB Fábrica, quando o material tem que ser retirado e FOB Cidade, quando o fornecedor coloca o material em uma transportadora escolhida pelo cliente",
  "utilitarios.accordion.glossario.definicao.item78":
    "Frete a cobrar, ou seja, a pagar. Pode ser pago em qualquer lugar, porém, em regra, é pago no destino.",
  "utilitarios.accordion.glossario.definicao.item79": "Transitário de Carga.",
  "utilitarios.accordion.glossario.definicao.item80":
    "Semelhante ao freight collect, porém, só pode ser pago no destino.",
  "utilitarios.accordion.glossario.definicao.item81":
    "Frete pré-pago ou frete pago. Diz-se do frete, em regra, pago na origem, por ocasião do embarque dos bens.",
  "utilitarios.accordion.glossario.definicao.item82": "Conhecimento Aéreo emitido pelo Consolidador da carga.",
  "utilitarios.accordion.glossario.definicao.item83":
    "É o potencial gerador de cargas do porto ou sua área de influência terrestre. O Hinterland depende, basicamente, do potencial de desenvolvimento da região em que o porto está localizado e dos custos de transporte terrestre e feeder",
  "utilitarios.accordion.glossario.definicao.item84":
    "Expressão que se usa para container, indicando que ele será ovado ou desovado na casa do embarcador ou consignatário",
  "utilitarios.accordion.glossario.definicao.item85":
    "Conhecimento Marítimo emitido por um freight forwarder (transitário de carga).",
  "utilitarios.accordion.glossario.definicao.item86":
    "Ponto central para coletar, separar e distribuir para uma determinada área ou região específica.",
  "utilitarios.accordion.glossario.definicao.item87":
    "Intermediate Bulk Container ou Contenedor Intermediário para Granel.",
  "utilitarios.accordion.glossario.definicao.item88":
    "sigla que identifica os 13 termos que padronizam a linguagem usada no mercado de exportação e importação.",
  "utilitarios.accordion.glossario.definicao.item89":
    "é atender ao cliente interno ou externo no momento exato de sua necessidade, com as quantidades necessárias para a operação/produção, evitando-se assim a manutenção de maiores estoques.",
  "utilitarios.accordion.glossario.definicao.item90":
    "processo de melhorias contínuas, com bom senso e baixos investimentos.",
  "utilitarios.accordion.glossario.definicao.item91":
    "Abreviatura de Letter of Credit (Carta de Crédito).LLP - Leading Logistics Provider ou Principal Fornecedor de Serviços Logísticos.",
  "utilitarios.accordion.glossario.definicao.item92":
    "expressão do transporte marítimo, que significa água que é posta nos porões para dar peso e equilíbrio ao navio, quando está sem carga; no transporte ferroviário significa camada de substâncias permeáveis como areia, saibro ou pedra britada, posta no leito das estradas de ferro e sobre a qual repousam os dormentes.",
  "utilitarios.accordion.glossario.definicao.item93":
    "estadia do navio no porto, que significa período previsto para acontecer a operação (atracar, carregar e zarpar).",
  "utilitarios.accordion.glossario.definicao.item94":
    "Trata-se de todas as atividades de movimentação e armazenagem, que facilitam o fluxo de produtos desde o ponto de aquisição da matéria-prima até o ponto de consumo final, assim como dos fluxos de informação que colocam os produtos em movimento, com o propósito de providenciar níveis de serviço adequados aos clientes a um custo razoável. (definição de Ronald H. Ballou no seu livro “Logística Empresarial”).",
  "utilitarios.accordion.glossario.definicao.item95":
    "No mercado é considerada como o caminho que a embalagem toma após a entrega dos materiais, no sentido da reciclagem das mesmas. Nunca voltando para a origem.",
  "utilitarios.accordion.glossario.definicao.item96":
    "Conhecimento Aéreo emitido pelo transportador ao consolidador da carga.",
  "utilitarios.accordion.glossario.definicao.item97":
    "São instrumentos utilizados para avaliar a performance de qualquer atividade logística. Podem ser relatórios, auditorias, etc. Não se pode melhorar aquilo que não mensuramos.",
  "utilitarios.accordion.glossario.definicao.item98":
    "Objetiva a integração econômica e comercial entre seus parceiros – Argentina, Brasil, Paraguai e Uruguai",
  "utilitarios.accordion.glossario.definicao.item99":
    "consiste na busca do(s) produto(s) diretamente junto ao(s) fornecedor(es), de forma programada, para atender sua necessidade de abastecimento.",
  "utilitarios.accordion.glossario.definicao.item100":
    "são os tipos/meios de transporte existentes. São eles: marítimo: (feito pelo mar), ferroviário (feito por ferrovias), rodoviário (feito por rodovias), hidroviário (feito em rios), dutoviário (feito pelos dutos) e aeroviário (feito de forma aérea).",
  "utilitarios.accordion.glossario.definicao.item101":
    "Sigla indicativa de Non-Vessel-Operating Common Carrier. Operador de Transporte Marítimo Sem Embarcação.",
  "utilitarios.accordion.glossario.definicao.item102": "Operador de Transporte Multimodal.",
  "utilitarios.accordion.glossario.definicao.item103": "Fluxos da fábrica para o concessionário.",
  "utilitarios.accordion.glossario.definicao.item104": "Relação de mercadorias ou volumes.",
  "utilitarios.accordion.glossario.definicao.item105":
    "Fixação de cargas a bordo do veículo ou em container, utilizando-se de materiais diversos.",
  "utilitarios.accordion.glossario.definicao.item106": "parte posterior do navio.",
  "utilitarios.accordion.glossario.definicao.item107":
    "Faz parte das normas de operação dos portos, e significa a tonelagem mínima estabelecida que será operada num período de seis horas.",
  "utilitarios.accordion.glossario.definicao.item108":
    "Pré-empilhamento de containers, para prepará-los para embarque.",
  "utilitarios.accordion.glossario.definicao.item109": "parte anterior do navio.",
  "utilitarios.accordion.glossario.definicao.item110": "Sigla de pre-shipment inspection (inspeção pré-embarque).",
  "utilitarios.accordion.glossario.definicao.item111":
    "Registro informatizado elaborado no Siscomex que aglutina num único documento as informações de natureza comercial, financeira, cambial e fiscal relativas a uma exportação.",
  "utilitarios.accordion.glossario.definicao.item112":
    "Pequeno vapor utilizado para rebocar navios ou manobrá-los com segurança em áreas dos portos.",
  "utilitarios.accordion.glossario.definicao.item113":
    "É o conjunto monolítico formado pela carroceria com o conjunto de dois eixos e pelo menos quatro rodas. É engatado na carroceria do caminhão para o transporte, formando um conjunto de duas carrocerias puxadas por um só caminhão.",
  "utilitarios.accordion.glossario.definicao.item114":
    "São locais assim declarados pela autoridade aduaneira competente, na zona primária ou na zona secundária, a fim de que neles possa ocorrer, sob controle aduaneiro, movimentação, armazenagem e despacho aduaneiro.",
  "utilitarios.accordion.glossario.definicao.item115":
    "Regime de Entreposto Industrial sob Controle Aduaneiro Informatizado – Permite que a empresa credenciada importe insumos com suspensão de impostos, sob controle aduaneiro, destinados à produção industrial de mercadorias a serem exportadas.",
  "utilitarios.accordion.glossario.definicao.item116": "Recinto Especial para Despacho Aduaneiro de Exportação.",
  "utilitarios.accordion.glossario.definicao.item117":
    "Significa trazer de volta ao País mercadoria que foi exportada temporariamente.",
  "utilitarios.accordion.glossario.definicao.item118":
    "Regime Aduaneiro Especial de Exportação e de Importação de Bens Destinados às Atividades de Pesquisa e de Lavra das Jazidas de Petróleo e de Gás Natural – Permite a exportação com saída ficta do território aduaneiro e posterior aplicação do regime de admissão temporária, bem como de partes e peças, como também a importação sob o regime de drawback.",
  "utilitarios.accordion.glossario.definicao.item119":
    "Denominado também Simplex, reúne num único documento os elementos relativos a uma exportação de valor até dez mil dólares ou seu equivalente em outras moedas.",
  "utilitarios.accordion.glossario.definicao.item120":
    "é o conjunto monolítico formado pela carroceria com um eixo e rodas. É engatado no cavalo mecânico ou trator para o transporte, ou ainda passa a ser utilizado como reboque, quando é engatado em umdolly. É muito utilizado no transporte de cana de açúcar.",
  "utilitarios.accordion.glossario.definicao.item121":
    "É um programa, dos países desenvolvidos, de benefícios tarifários de isenção total ou parcial do imposto de importação sobre produtos adquiridos de países em desenvolvimento.",
  "utilitarios.accordion.glossario.definicao.item122": "Agente Marítimo.",
  "utilitarios.accordion.glossario.definicao.item123":
    "Na maioria dos casos é o próprio Beneficiário. Não havendo instrução em contrário, no Crédito, poderá ser um terceiro. É o mesmo que Consignator (consignante ou consignador).",
  "utilitarios.accordion.glossario.definicao.item124":
    "tipo de carroceria de caminhão, que tem lonas retráteis em suas laterais.",
  "utilitarios.accordion.glossario.definicao.item125":
    "Sistema de Informações Logísticas que providencia a informação especificamente necessária para subsídio da administração logística em todos os seus níveis hierárquicos.",
  "utilitarios.accordion.glossario.definicao.item126":
    "Sistema informatizado e controlado pelos órgãos governamentais intervenientes no comércio exterior, que controlam as atividades de registro, acompanhamento e controle das operações.",
  "utilitarios.accordion.glossario.definicao.item127": "Taxa adicional cobrada além do frete normal.",
  "utilitarios.accordion.glossario.definicao.item128": "Gerenciamento da Cadeia de Abastecimento.",
  "utilitarios.accordion.glossario.definicao.item129":
    "Diferença entre os pesos bruto e líquido, representando a embalagem da mercadoria, o peso do container vazio, ou peso do veículo vazio.",
  "utilitarios.accordion.glossario.definicao.item130": "Tarifa Externa Comum.",
  "utilitarios.accordion.glossario.definicao.item131":
    "Twenty Foot Equivalent Unit. Tamanho padrão de contêiner de 20 pés.",
  "utilitarios.accordion.glossario.definicao.item132": "Toneladas por quilômetro útil.",
  "utilitarios.accordion.glossario.definicao.item133":
    "Transportation Management Systems ou Sistemas de Gerenciamento de Transporte.",
  "utilitarios.accordion.glossario.definicao.item134":
    "Transferir mercadorias/produtos de um para outro meio de transporte ou veículo, no decorrer do percurso da operação de entrega.",
  "utilitarios.accordion.glossario.definicao.item135":
    "Regime aduaneiro especial que permite o transporte de mercadoria no território nacional, sob controle aduaneiro, de um ponto a outro do território aduaneiro, com suspensão do pagamento de tributos.",
  "utilitarios.accordion.glossario.definicao.item136":
    "é a integração dos serviços de mais de um modo de transporte, com emissão de documentos independentes, onde cada transportador assume responsabilidade por seu transporte.",
  "utilitarios.accordion.glossario.definicao.item137":
    "é a integração dos serviços de mais de um modo de transporte, utilizados para que determinada carga percorra o caminho entre o remetente e seu destinatário, entre os diversos modais existentes, sendo emitido apenas um único conhecimento de transporte pelo único responsável pelo transporte, que é o OTM - Operador de Transporte Multimodal.",
  "utilitarios.accordion.glossario.definicao.item138":
    "Transbordo. Descarregamento e recarregamento dos bens, durante o curso da viagem, utilizando sempre o mesmo meio de transporte. Na mensagem SWIFT MT 700, indica se o transbordo é proibido ou permitido.",
  "utilitarios.accordion.glossario.definicao.item139":
    "é uma asa-delta motorizada que vem equipada com rodas e/ou flutuadores e assentos de fibra de vidro.",
  "utilitarios.accordion.glossario.definicao.item140":
    "Caminhão que tem o eixo duplo na carroceria, ou seja, são 2 eixos juntos. O objetivo é aguentar mais peso e propiciar melhor desempenho ao veículo.",
  "utilitarios.accordion.glossario.definicao.item141":
    "é agregar diversos pacotes ou embalagens menores numa carga unitária maior.",
  "utilitarios.accordion.glossario.definicao.item142":
    "Documento emitido pelo DMM (Departamento da Marinha Mercante), para que mercadorias prescritas, quer dizer, aquelas importadas com benefícios governamentais, possam ser transportadas em navio estrangeiro na falta de navio de bandeira nacional.",
  "utilitarios.accordion.glossario.definicao.item143": "Warehouse Control Systems ou Sistemas de Controle de Armazém.",
  "utilitarios.accordion.glossario.definicao.item144":
    "É a taxa cobrada pela administração de um porto para utilização do mesmo, nas operações que envolvem atracação, carga, descarga e estocagem nas docas e armazéns ligados ao porto.",
  "utilitarios.accordion.glossario.definicao.item145":
    "Warehouse Management Systems ou Sistemas de Gerenciamento de Armazém.",
  "utilitarios.accordion.glossario.definicao.item146":
    "Área demarcada pela autoridade aduaneira nos portos, aeroportos e pontos de fronteira alfandegados",
  "utilitarios.accordion.glossario.definicao.item147":
    "Compreende o território aduaneiro, com exclusão da zona primária, nela incluídas as águas territoriais e o espaço aéreo.",

  "utilitarios.accordion.glossario.termo.item1": "ACF – Attainable Cubic Feet",
  "utilitarios.accordion.glossario.termo.item2": "Acknowledgement of Receipt ou Confirmação de Recebimento",
  "utilitarios.accordion.glossario.termo.item3": "AD Valrem",
  "utilitarios.accordion.glossario.termo.item4": "Admissão temporária",
  "utilitarios.accordion.glossario.termo.item5": "ADR",
  "utilitarios.accordion.glossario.termo.item6": "AFRMM",
  "utilitarios.accordion.glossario.termo.item7": "Aladi (Associação Latino-Americana de Integração)",
  "utilitarios.accordion.glossario.termo.item8": "Amostras sem valor comercial",
  "utilitarios.accordion.glossario.termo.item9": "Armazém ou Warehouse",
  "utilitarios.accordion.glossario.termo.item10": "AWB",
  "utilitarios.accordion.glossario.termo.item11": "B/L (Bill of Lading)",
  "utilitarios.accordion.glossario.termo.item12": "Back to Back",
  "utilitarios.accordion.glossario.termo.item13": "Bagagem",
  "utilitarios.accordion.glossario.termo.item14": "Barra",
  "utilitarios.accordion.glossario.termo.item15": "Bi-trem ou Reboque",
  "utilitarios.accordion.glossario.termo.item16": "Bombordo",
  "utilitarios.accordion.glossario.termo.item17": "Bonded Warehousing",
  "utilitarios.accordion.glossario.termo.item18": "Break-Bulk",
  "utilitarios.accordion.glossario.termo.item19": "Brokerage Houses",
  "utilitarios.accordion.glossario.termo.item20": "Bulk Cargo",
  "utilitarios.accordion.glossario.termo.item21": "Bulk Carrier",
  "utilitarios.accordion.glossario.termo.item22": "Bulk Container",
  "utilitarios.accordion.glossario.termo.item23": "Cabotagem",
  "utilitarios.accordion.glossario.termo.item24": "Cábrea",
  "utilitarios.accordion.glossario.termo.item25": "Calado",
  "utilitarios.accordion.glossario.termo.item26": "Capatazia",
  "utilitarios.accordion.glossario.termo.item27": "Carreta baú",
  "utilitarios.accordion.glossario.termo.item28": "Carreta isotérmica",
  "utilitarios.accordion.glossario.termo.item29": "Cavalo Mecânico",
  "utilitarios.accordion.glossario.termo.item30": "CCR",
  "utilitarios.accordion.glossario.termo.item31": "CFR",
  "utilitarios.accordion.glossario.termo.item32": "CI",
  "utilitarios.accordion.glossario.termo.item33": "CIDE (Contribuição de Intervenção sobre o Domínio Econômico)",
  "utilitarios.accordion.glossario.termo.item34": "CIP",
  "utilitarios.accordion.glossario.termo.item35": "Comboio",
  "utilitarios.accordion.glossario.termo.item36": "Conhecimento de Transporte",
  "utilitarios.accordion.glossario.termo.item37": "Consignee",
  "utilitarios.accordion.glossario.termo.item38": "Consolidação de Cargas",
  "utilitarios.accordion.glossario.termo.item39": "Contêiner",
  "utilitarios.accordion.glossario.termo.item40": "Convés",
  "utilitarios.accordion.glossario.termo.item41": "CPC",
  "utilitarios.accordion.glossario.termo.item42": "CPT",
  "utilitarios.accordion.glossario.termo.item43": "Cubagem ou Cubage",
  "utilitarios.accordion.glossario.termo.item44": "Custo Logístico",
  "utilitarios.accordion.glossario.termo.item45": "DAC (Depósito Alfandegado Certificado)",
  "utilitarios.accordion.glossario.termo.item46": "DAF",
  "utilitarios.accordion.glossario.termo.item47": "DDP ou Door to Door",
  "utilitarios.accordion.glossario.termo.item48": "DDU",
  "utilitarios.accordion.glossario.termo.item49": "Dead line",
  "utilitarios.accordion.glossario.termo.item50": "Demurrage ou Sobreestadia",
  "utilitarios.accordion.glossario.termo.item51": "DEQ",
  "utilitarios.accordion.glossario.termo.item52": "DES",
  "utilitarios.accordion.glossario.termo.item53": "DI (Declaração de importação)",
  "utilitarios.accordion.glossario.termo.item54": "Docas ou Docks",
  "utilitarios.accordion.glossario.termo.item55": "Dragagem",
  "utilitarios.accordion.glossario.termo.item56": "Draw-back",
  "utilitarios.accordion.glossario.termo.item57": "DSE",
  "utilitarios.accordion.glossario.termo.item58": "DSI",
  "utilitarios.accordion.glossario.termo.item59": "EADI",
  "utilitarios.accordion.glossario.termo.item60": "ELQ",
  "utilitarios.accordion.glossario.termo.item61": "Embalagem ou Package",
  "utilitarios.accordion.glossario.termo.item62": "Entreposto aduaneiro",
  "utilitarios.accordion.glossario.termo.item63": "Entreposto industrial sob controle informatizado",
  "utilitarios.accordion.glossario.termo.item64": "Estibordo",
  "utilitarios.accordion.glossario.termo.item65": "Estivador",
  "utilitarios.accordion.glossario.termo.item66": "ETA",
  "utilitarios.accordion.glossario.termo.item67": "ETS",
  "utilitarios.accordion.glossario.termo.item68": "Exportação temporária",
  "utilitarios.accordion.glossario.termo.item69": "EXW",
  "utilitarios.accordion.glossario.termo.item70": "FAS",
  "utilitarios.accordion.glossario.termo.item71": "FCA",
  "utilitarios.accordion.glossario.termo.item72": "FCL",
  "utilitarios.accordion.glossario.termo.item73": "FCR",
  "utilitarios.accordion.glossario.termo.item74": "Feeder",
  "utilitarios.accordion.glossario.termo.item75": "Feeder Ship",
  "utilitarios.accordion.glossario.termo.item76": "FIO",
  "utilitarios.accordion.glossario.termo.item77": "FOB",
  "utilitarios.accordion.glossario.termo.item78": "Freight Collect",
  "utilitarios.accordion.glossario.termo.item79": "Freight Forwarders",
  "utilitarios.accordion.glossario.termo.item80": "Freight Payable at Destination",
  "utilitarios.accordion.glossario.termo.item81": "Freight Prepaid ou Freight Paid",
  "utilitarios.accordion.glossario.termo.item82": "HAWB (House Airway Bill)",
  "utilitarios.accordion.glossario.termo.item83": "Hinterland",
  "utilitarios.accordion.glossario.termo.item84": "House (casa)",
  "utilitarios.accordion.glossario.termo.item85": "House Bill of Lading ou House B/L",
  "utilitarios.accordion.glossario.termo.item86": "Hub",
  "utilitarios.accordion.glossario.termo.item87": "IBC",
  "utilitarios.accordion.glossario.termo.item88": "Incoterms",
  "utilitarios.accordion.glossario.termo.item89": "Just-in-Time ou JIT",
  "utilitarios.accordion.glossario.termo.item90": "Kaizen",
  "utilitarios.accordion.glossario.termo.item91": "L/C",
  "utilitarios.accordion.glossario.termo.item92": "Lastro",
  "utilitarios.accordion.glossario.termo.item93": "Layday ou Laytime",
  "utilitarios.accordion.glossario.termo.item94": "Logística Empresarial",
  "utilitarios.accordion.glossario.termo.item95": "Logística Reversa ou Inversa",
  "utilitarios.accordion.glossario.termo.item96": "MAWB (Master Airway Bill)",
  "utilitarios.accordion.glossario.termo.item97": "Medidas de desempenho",
  "utilitarios.accordion.glossario.termo.item98": "Mercosul (Mercado Comum do Sul)",
  "utilitarios.accordion.glossario.termo.item99": "Milk Run",
  "utilitarios.accordion.glossario.termo.item100": "Modais",
  "utilitarios.accordion.glossario.termo.item101": "NVOCC",
  "utilitarios.accordion.glossario.termo.item102": "OTM",
  "utilitarios.accordion.glossario.termo.item103": "Outbound",
  "utilitarios.accordion.glossario.termo.item104": "Packing List (Romaneio)",
  "utilitarios.accordion.glossario.termo.item105": "Peação",
  "utilitarios.accordion.glossario.termo.item106": "Popa",
  "utilitarios.accordion.glossario.termo.item107": "Prancha de carregamento",
  "utilitarios.accordion.glossario.termo.item108": "Pre-stacking",
  "utilitarios.accordion.glossario.termo.item109": "Proa",
  "utilitarios.accordion.glossario.termo.item110": "PSI",
  "utilitarios.accordion.glossario.termo.item111": "RE (Registro de Exportação)",
  "utilitarios.accordion.glossario.termo.item112": "Rebocador",
  "utilitarios.accordion.glossario.termo.item113": "Reboque ou bi-trem",
  "utilitarios.accordion.glossario.termo.item114": "Recintos alfandegados",
  "utilitarios.accordion.glossario.termo.item115": "Recof",
  "utilitarios.accordion.glossario.termo.item116": "REDEX",
  "utilitarios.accordion.glossario.termo.item117": "Reimportação",
  "utilitarios.accordion.glossario.termo.item118": "Repetro",
  "utilitarios.accordion.glossario.termo.item119": "RES (Registro de Exportação Simplificado)",
  "utilitarios.accordion.glossario.termo.item120": "Semi-reboque",
  "utilitarios.accordion.glossario.termo.item121": "SGP (Sistema Geral de Preferências)",
  "utilitarios.accordion.glossario.termo.item122": "Ship Broker",
  "utilitarios.accordion.glossario.termo.item123": "Shipper (Embarcador)",
  "utilitarios.accordion.glossario.termo.item124": "Sider",
  "utilitarios.accordion.glossario.termo.item125": "SIL",
  "utilitarios.accordion.glossario.termo.item126": "Siscomex (Sistema Integrado de Comércio Exterior)",
  "utilitarios.accordion.glossario.termo.item127": "Sobretaxa ou Surcharge",
  "utilitarios.accordion.glossario.termo.item128": "Supply Chain Management",
  "utilitarios.accordion.glossario.termo.item129": "Tara",
  "utilitarios.accordion.glossario.termo.item130": "TEC",
  "utilitarios.accordion.glossario.termo.item131": "TEU",
  "utilitarios.accordion.glossario.termo.item132": "TKU",
  "utilitarios.accordion.glossario.termo.item133": "TMS",
  "utilitarios.accordion.glossario.termo.item134": "Transbordo ou Transhipment",
  "utilitarios.accordion.glossario.termo.item135": "Trânsito aduaneiro",
  "utilitarios.accordion.glossario.termo.item136": "Transporte Intermodal",
  "utilitarios.accordion.glossario.termo.item137": "Transporte Multimodal",
  "utilitarios.accordion.glossario.termo.item138": "Transshipment",
  "utilitarios.accordion.glossario.termo.item139": "Trick",
  "utilitarios.accordion.glossario.termo.item140": "Truck",
  "utilitarios.accordion.glossario.termo.item141": "Unitização",
  "utilitarios.accordion.glossario.termo.item142": "Waiver",
  "utilitarios.accordion.glossario.termo.item143": "WCS",
  "utilitarios.accordion.glossario.termo.item144": "Wharfage ou Taxa de atracação",
  "utilitarios.accordion.glossario.termo.item145": "WMS",
  "utilitarios.accordion.glossario.termo.item146": "Zona primária",
  "utilitarios.accordion.glossario.termo.item147": "Zona secundária",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  "contato.title": "Entre em contato",
  "contato.form.placeholder1": "Nome",
  "contato.form.placeholder2": "E-mail",
  "contato.form.placeholder3": "Assunto",
  "contato.form.placeholder4": "Mensagem",
  "contato.form.button": "Enviar",
  "contato.from.message.success": "Mensagem enviada com sucesso!",
  "contato.from.message.error": "Ocorreu um erro ao enviar a sua mensagem, por favor tente novamente.",
  "contato.title.card.title1": "Endereço",
  "contato.title.card.text1":
    "Av. Sete de Setembro, nº 776, 5º andar, sala 501, Ed. 7 Eco Office, CEP 88.301-200, Centro -Itajaí/SC",
  "contato.title.card.title2": "E-mail",
  "contato.title.card.title3": "Telefone"
};
