import React from "react";
import { Helmet } from "react-helmet";
import { Header, Newsletter, Footer } from "../template";
import $ from "jquery";

const Base = props => (
  <React.Fragment>
    <Helmet>
      <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{process.env.head_title}</title>
      <link rel="icon" type="image/png" href={`${process.env.asset_path}/assets/images/favicon.ico`} sizes="16x16" />

      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Quicksand"></link>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"
        integrity="sha384-DNOHZ68U8hZfKXOrtjWvjxusGo9WQnrNx2sqG0tfsghAvtVlRW3tvkXWZh58N9jp"
        crossorigin="anonymous"
      />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css" />
      <link rel="stylesheet" href={`${process.env.asset_path}/assets/css/main.css`} type="text/css" />
      <link
        rel="stylesheet"
        href={`${process.env.asset_path}/assets/lib/bootstrap/css/bootstrap.min.css`}
        type="text/css"
      />
      <link rel="stylesheet" href={`${process.env.asset_path}/assets/lib/animate/animate.min.css`} type="text/css" />
      <link
        rel="stylesheet"
        href={`${process.env.asset_path}/assets/lib/react-flags-select/react-flags-select.css`}
        type="text/css"
      />
      <link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/leaflet.css">
        
      </link>
      <script src="https://unpkg.com/react-leaflet/dist/react-leaflet.min.js"></script>
      <script src="https://unpkg.com/react-leaflet/dist/react-leaflet.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/react-leaflet/2.0.0/react-leaflet.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/react-leaflet/2.0.0/react-leaflet.js"></script>

      <script src={`${process.env.asset_path}/assets/js/main.js`} type="text/javascript" />
    </Helmet>
    <Header />
    <div id="children">{props.children}</div>
    <Newsletter />
    <Footer />
  </React.Fragment>
);

export default Base;
