import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class NavLink extends React.Component {
    render() {
        var isActive = this.context.router ? this.context.router.route.location.pathname === this.props.to : false;
        var className = isActive ? 'menu-active' : '';

        return (
            <React.Fragment>
                <li className={className}>
                    <Link {...this.props}>
                        {this.props.children}
                    </Link>
                </li>
            </React.Fragment>
        );
    }
}

NavLink.contextTypes = {
    router: PropTypes.object
};

export default NavLink;