import React, { Fragment } from "react";
import Cotacao from "../../../context/Cotacao.context";
import { FormattedMessage } from "react-intl";

export default class TableCotacao extends React.Component {
    static contextType = Cotacao.Context;

    componentWillMount() {
        this.context.dispatch(Cotacao.Actions.GET_COTACAO);
    }

    render() {
        return (
            <Fragment>
                <div className="col-md-12" id="tableCotacao">
                    <h4><FormattedMessage id="homepage.cotacao.title" /></h4>
                    <Cotacao.Consumer>
                        {value => (
                            <table className="table table-hover" id="TableBody">
                                <thead>
                                    <tr>
                                        <th scope="col"><FormattedMessage id="homepage.cotacao.col1" /></th>
                                        <th scope="col" id="TableRow"><FormattedMessage id="homepage.cotacao.col2" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" id="TableText"><FormattedMessage id="homepage.cotacao.col1.linha1" /></th>
                                        <td id="TableRow">{value.state.USD}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" id="TableText"><FormattedMessage id="homepage.cotacao.col1.linha2" /></th>
                                        <td id="TableRow">{value.state.EUR}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </Cotacao.Consumer>
                </div>
            </Fragment>
        );
    }
}
