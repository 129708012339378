import React from "react";
import { FormattedMessage } from "react-intl";
export default class Carousel extends React.Component {

  render() {

    return (
      <section id="intro">
        <div className="intro-container">
          <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">
            <ol className="carousel-indicators">
            </ol>
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active" style={{ backgroundImage: `url(${process.env.asset_path}/assets/images/carousel2.jpg)` }}>
                <div className="carousel-container">
                  <div className="carousel-content">
                    <p id="carouselText"><FormattedMessage id="homepage.carousel.title1" /></p>
                    <h3 id="carouselDesc"><FormattedMessage id="homepage.carousel.subtitle1" /></h3>
                  </div>
                </div>
              </div>
              <div className="carousel-item" style={{ backgroundImage: `url(${process.env.asset_path}/assets/images/carousel4.jpg)` }}>
                <div className="carousel-container">
                  <div className="carousel-content">
                    <p id="carouselText"><FormattedMessage id="homepage.carousel.title2" /></p>
                    <h3 id="carouselDesc"><FormattedMessage id="homepage.carousel.subtitle2" /></h3>
                  </div>
                </div>
              </div>
              <div className="carousel-item" style={{ backgroundImage: `url(${process.env.asset_path}/assets/images/carousel3.jpg)` }}>
                <div className="carousel-container">
                  <div className="carousel-content">
                    <p id="carouselText"><FormattedMessage id="homepage.carousel.title3" /></p>
                    <h3 id="carouselDesc"><FormattedMessage id="homepage.carousel.subtitle3" /></h3>
                  </div>
                </div>
              </div>
            </div>
            <a className="carousel-control-next" href="#introCarousel" role="button" data-slide="next"> <span className="carousel-control-next-icon fa fa-angle-right" aria-hidden="true"></span> <span className="sr-only">Next</span> </a>
            <a className="carousel-control-prev" href="#introCarousel" role="button" data-slide="prev"> <span className="carousel-control-prev-icon fa fa-angle-left" aria-hidden="true"></span> <span className="sr-only">Previous</span> </a>
          </div>
        </div>
      </section>
    );
  }
}
