import React, { Component, Fragment} from "react";
import Carousel from "../template/carousel.react";
import FeaturedArea from "../template/featuredArea.react";

export default class Home extends Component {
    
    render() {
        
        return (
            <Fragment>
                <Carousel/>
                <FeaturedArea/>
            </Fragment>
        );
    }
}