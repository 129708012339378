import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import TableConversao from "../template/tables/tableConversao";
import TableContainers from "../template/tables/tableContainers";
import TableCalculo from "../template/tables/tableCalculo";
import TableUteis from "../template/tables/tableUteis";
import TableGlossario from "../template/tables/tableGlossario";

import { FormattedMessage } from "react-intl";

export default class Utilitarios extends React.Component {
  render() {
    return (
      <section id="Utilitarios">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 id="utilitariosTitle"><FormattedMessage id="utilitarios.accordion.glossario.name" /></h1>
            </div>
            <div className="col-md-12" id="UtilAccordion">
              <Accordion id="MainAccordion" accordion={false}>
                <AccordionItem>
                  <AccordionItemTitle>
                    <h5>
                      <FormattedMessage id="utilitarios.accordion.conversao.name" /> <div className="accordion__arrow" />
                    </h5>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <TableConversao />
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemTitle>
                    <h5>
                      <FormattedMessage id="utilitarios.accordion.containers.name" /> <div className="accordion__arrow" />
                    </h5>
                  </AccordionItemTitle>
                  <AccordionItemBody id="accordionItemContainers">
                    <TableContainers />
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemTitle>
                    <h5>
                      <FormattedMessage id="utilitarios.accordion.maritimo.name" /> <div className="accordion__arrow" />
                    </h5>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <TableCalculo />
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemTitle>
                    <h5>
                      <FormattedMessage id="utilitarios.accordion.uteis.name" /> <div className="accordion__arrow" />
                    </h5>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <TableUteis />
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemTitle>
                    <h5>
                      <FormattedMessage id="utilitarios.accordion.glossario.name" /> <div className="accordion__arrow" />
                    </h5>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <TableGlossario />
                  </AccordionItemBody>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
