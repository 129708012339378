import fetch from "node-fetch"
import { callbacks } from "../components/util/callback"

import { DateTime } from 'luxon';

import { createContext, createActions, createCRUDActions } from "./ContextFactory";

function toDateString(todayObj) {
	return (todayObj.c.month + "-" + todayObj.c.day + "-" + todayObj.c.year)
}

function tryPTAX(todayObj, currency) {
	return fetch("https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoMoedaDia(moeda=@moeda,dataCotacao=@dataCotacao)?%40moeda='" + currency + "'&%40dataCotacao='" + toDateString(todayObj) + "'&%24format=json")
		.then(res => res.json())
		.then(response => {

			var hasPTAX = []

			hasPTAX = response.value.filter(e => {
				return e.tipoBoletim == "Fechamento PTAX"
			})
			if (hasPTAX.length == 0) {
				var newTodayObj = todayObj.plus({ days: -1 })
				return tryPTAX(newTodayObj, currency)
			} else {
				return hasPTAX[0].cotacaoVenda;
			}

		})
		.catch(error => {
			console.error('MERDA dolar:', JSON.stringify(error));
			return 0;
		});
}

function getCNY(USD) {
	return fetch("http://www.apilayer.net/api/live?access_key=000879f3cd5482e134d1a13925cf3291&currencies=CNY&format=1")
		.then(res => res.json())
		.then(response => {
			var CNY = JSON.stringify(response.quotes.USDCNY)

			CNY = CNY / USD

			return CNY.toFixed(4);
		})
		.catch(error => {
			return 0;
		});

}

const actions = createActions([
	...createCRUDActions(),
	"GET_COTACAO",
	"GET_COTACAO_DOLAR"
]);

const initState = {
	CNY: null,
	USD: null,
	EUR: null
};

const reducer = async (action, data, state, cbs) => {
	switch (action) {
		case actions.GET_COTACAO:

			var todayObj = DateTime.local().setZone("America/Sao_Paulo")
			var USD = tryPTAX(todayObj, "USD")
			var EUR = tryPTAX(todayObj, "EUR")
			var returnProm = Promise.all([USD, EUR])

			return returnProm.then((resArr) => {
				return getCNY(resArr[0]).then((response) => {
					return { USD: resArr[0], EUR: resArr[1], CNY: response }
				})
			})
	}
};

export default createContext(initState, reducer, actions);
