import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
export default class TableCalculo extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="row" id="accordionRow">
          <br />
          <p><FormattedMessage id="utilitarios.accordion.maritimo.text" /></p>
          <div className="table-responsive">
            <table className="table table-hover" id="TableBody">
              <thead>
                <tr>
                  <th scope="col"><FormattedMessage id="utilitarios.accordion.maritimo.table.col1" /></th>
                  <th scope="col" id="TableRow">
                  <FormattedMessage id="utilitarios.accordion.maritimo.table.col2" />
                  </th>
                  <th scope="col" id="TableRow">
                  <FormattedMessage id="utilitarios.accordion.maritimo.table.col3" />
                  </th>
                  <th scope="col" id="TableRow">
                  <FormattedMessage id="utilitarios.accordion.maritimo.table.col4" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" id="TableText">
                  <FormattedMessage id="utilitarios.accordion.maritimo.table.linha1" />
                  </th>
                  <td id="TableRow">1.500Kg</td>
                  <td id="TableRow">2,10m³</td>
                  <td id="TableRow">2,10 ton</td>
                </tr>
                <tr>
                  <th scope="row" id="TableText">
                  <FormattedMessage id="utilitarios.accordion.maritimo.table.linha2" />
                  </th>
                  <td id="TableRow">5Kg</td>
                  <td id="TableRow">0,064m³</td>
                  <td id="TableRow">1 ton</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <p>
          <FormattedMessage id="utilitarios.accordion.maritimo.paragraph" />
          </p>
        </div>
      </Fragment>
    );
  }
}
