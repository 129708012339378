import fetch from "node-fetch"
import { callbacks } from "../components/util/callback"

import { createContext, createActions, createCRUDActions } from "./ContextFactory";

const actions = createActions([
	...createCRUDActions(),
	"ADD_MEMBER_NEWSLETTER"
]);

const initState = {
	name: "",
	email: ""
};

const reducer = async (action, data, state, cbs) => {
	switch (action) {
		case actions.ADD_MEMBER_NEWSLETTER:

			var options = {
				method: "POST",
				body: JSON.stringify(data)
			}

			//console.log(options)

			fetch(`${process.env.api_path}/api/v1/addMemberNewsletter`, options)
				.then(res => {
					callbacks(cbs.onSuccess, res);
				})
				.catch(err => {
					callbacks(cbs.onError, data, err);
				});

			break;
	}
};

export default createContext(initState, reducer, actions);
