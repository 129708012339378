import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AppContainer } from "react-hot-loader";
import App from "./app";
import Newsletter from "./context/Newsletter.context"

const appDOM = document.getElementById("app");

const render = () => {
  ReactDOM.hydrate(
    <AppContainer>
      <BrowserRouter>
        <Newsletter.Provider>
          <App />
        </Newsletter.Provider>
      </BrowserRouter>
    </AppContainer>,
    appDOM
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./app", () => {
    const NextApp = require("./app").default; // Get the updated code
    render(NextApp);
  });
}
