import fetch from "node-fetch"
import { callbacks } from "../components/util/callback"

import { createContext, createActions, createCRUDActions } from "./ContextFactory";

const actions = createActions([
    ...createCRUDActions(),
    "SEND_MESSAGE_CONTACT"
]);

const initState = {
    name: "",
    email: "",
    assunto: "",
    message: "",
    msgSuccess: "",
    msgError: ""
};

const reducer = async (action, data, state, cbs) => {
    switch (action) {
        case actions.SEND_MESSAGE_CONTACT:

            var options = {
                method: "POST",
                body: JSON.stringify(data)
            }

            //console.log(options)

            fetch(`${process.env.api_path}/sendContactForm`, options)
                .then(res => {
                    callbacks(cbs.onSuccess, res);
                })
                .catch(err => {
                    callbacks(cbs.onError, data, err);
                });

            break;
    }
};

export default createContext(initState, reducer, actions);
