export const callback = async (cb, data, err) => {
  if (cb && typeof cb.funct == 'function') {
    if (cb.delay) {
      setTimeout(() => { cb.funct(data, err) }, cb.delay);
    } else {
      cb.funct(data, err)
    }
  }
}

export const callbacks = async (cbs, data, err) => {
  if (cbs && cbs != null) {

    if (typeof cbs == 'object') {
      callback(cbs, data, err);
    }

    if (cbs.length > 0) {
      cbs.forEach(cb => {
        callback(cb, data, err);
      });
    }
  }
}