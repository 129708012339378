import React from 'react';
import PropTypes from 'prop-types';

import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';

/*
SCROLL INTO VIEW

Purpose:
  This modular component enables hash links
  eg. (www.xyz.com/somepage#someID)
  and plays nice with react router 4

Usage:
  Wrap this component around a single div with an ID

Example:
  <ScrollIntoView  id={this.props.location.hash}>
    <div id="someID">
      ... loads of content...
    </div>
  </ScrollIntoView>

  <a href="/somepage#someID"> IN-PAGE ANCHOR </a>

 */

class ScrollIntoView extends React.Component {


  componentDidMount() {
    this.scroll();
  }

  componentDidUpdate() {
    this.scroll();
  }

  scroll() {
    const { id } = this.props;
    if (!id) {
      document.documentElement.style.overflow = "auto";
      window.scrollTo(0, 0);
      return;
    }
    const element = document.querySelector(id);
    if (element) {
      scrollIntoViewIfNeeded(element, {
        block: "start",
        behavior: actions =>
          // list is sorted from innermost (closest parent to your target) to outermost (often the document.body or viewport)
          actions.forEach(({ el, top, left }) => {
            // implement the scroll anyway you want
            el.scrollTop = top - 60
            el.scrollLeft = left

            // If you need the relative scroll coordinates, for things like window.scrollBy style logic or whatever, just do the math
            const offsetTop = el.scrollTop - top
            const offsetLeft = el.scrollLeft - left
          })
      });
    }
  }

  render() {
    return this.props.children;
  }
}
ScrollIntoView.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired
  ])
};
export default ScrollIntoView;