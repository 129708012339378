import React, { createContext as ReactCreateContext } from "react";

export const createContext = (initialState, reducer, actions) => {
  class Provider extends React.Component {
    state = initialState;

    async dispatch(action, value, callback = { onComplete: [], onSuccess: [], onError: [], onFinish: [] }) {
      const reducerResponse = reducer(action, value, {
        state: this.state,
        setState: this.setState.bind(this),
        dispatch: this.dispatch.bind(this)
      }, callback);
      if (reducerResponse instanceof Promise) {
        await reducerResponse.then(res => {
          var newState = {
            ...this.state,
            ...res,
          };
          this.setState(newState, typeof callback.onFinish == 'function' ? () => callback.onFinish(res) : () => { });
        })
      } else {
        var newState = {
          ...this.state,
          ...reducerResponse,
        };
        this.setState(newState, typeof callback.onFinish == 'function' ? () => callback.onFinish(res) : () => { });
      }
    }

    render() {
      return (
        <Context.Provider value={{ state: this.state, dispatch: this.dispatch.bind(this) }}>
          {this.props.children}
        </Context.Provider>
      )
    }
  }

  const Context = ReactCreateContext({ state: initialState, dispatch: Provider.dispatch });

  return {
    Context,
    Provider,
    Consumer: Context.Consumer,
    Actions: actions,
  };
}

export const createActions = (actions) => {
  return actions.reduce((obj, action) => {
    obj[action] = action;
    return obj;
  }, {});
}

export const createCRUDActions = () => {
  return ["CREATE", "READ", "UPDATE", "DELETE"];
}

export default {
  createContext,
  createActions,
  createCRUDActions
};