import React from "react";
import Language from "../../context/Language.context";

export default class FlagDropdown extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {flag: "uk"};
  }
  
  static contextType = Language.Context;
  
  state = {
    isDropdownDisplayed: false,
    name: ""
  };
  
  handleClick(e, intl) {
    e.preventDefault();
    this.context.dispatch(Language.Actions.CHANGE_LANGUAGE, intl);
  }
  
  onSubmitSaveForm(e) {
    e.preventDefault();
    this.context.dispatch(Newsletter.Actions.ADD_MEMBER_NEWSLETTER, this.state, {
      onSuccess: {
        funct: res => {
          // console.log("DEU CERTO")
          this.setState({ msgSuccess: "Você assinou nossa Newsletter com sucesso." });
        }
      },
      onError: {
        funct: (res, err) => {
          //console.log("DEU NADA", err)
          this.setState({ msgError: "Ocorreu um erro ao assinar a nossa Newsletter, por favor tente novamente." });
        }
      }
    });
  }
  
  render() {
    
    const handleClick = (e, intl, flagType) => {
      e.preventDefault();
      this.setState({
        flag: flagType
      });
      this.context.dispatch(Language.Actions.CHANGE_LANGUAGE, intl);
    }

    return (
      <React.Fragment>
        <div className="dropdownFlag">
          <button className="dropbtn">
            <img src={`${process.env.asset_path}/assets/images/${this.state.flag}.png`} className="mainFlagImg" />
          </button>
          <ul className="dropdown-content">
            <li onClick={e => handleClick(e, "pt-BR", "br")}>
              <img src={`${process.env.asset_path}/assets/images/br.png`} className="mainFlagImg" />
            </li>
            <li onClick={e => handleClick(e, "en-US", "uk")}>
              <img src={`${process.env.asset_path}/assets/images/uk.png`} className="mainFlagImg" />
            </li>
            <li
            // onClick={e => this.handleClick(e, "zh-CN")}
            >
              <img src={`${process.env.asset_path}/assets/images/ch.png`} className="flagImg" />
            </li>
            <li
            // onClick={e => this.handleClick(e, "pt-BR")}
            >
              <img src={`${process.env.asset_path}/assets/images/es.png`} className="flagImg" />
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}
