export default {
  "navbar.home": "Home",
  "navbar.institucional": "Institutional",
  "navbar.servicos": "Services",
  "navbar.utilitarios": "Utilities",
  "navbar.contato": "Contact",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "newsletter.message.success": "You have successfully subscribed to our newsletter.",
  "newsletter.message.error": "There was an error subscribing to our newsletter, please try again.",
  "newsletter.title": "Sign up for our Newsletter",
  "newsletter.subtitle":
    "Stay on top of what we do, receive in your email news about the tools and processes we use in our daily lives, free from spam and advertisements!",
  "newsletter.placeholder.name": "What's your name?",
  "newsletter.placeholder.email": "What's your e-mail?",
  "newsletter.placeholder.button": "Send",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "footer.endereco.title": "Adress: ",
  "footer.endereco":
    "Av. Sete de Setembro, nº 776, 5º andar, sala 501, Ed. 7 Eco Office, CEP 88.301-200, Centro -Itajaí/SC - Telefone e Whatsapp: +55 (47) 98868-8772",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "homepage.carousel.title1": "Import",
  "homepage.carousel.subtitle1": "By Order and By Third Party Order and Account",
  "homepage.carousel.title2": "Advisory",
  "homepage.carousel.subtitle2": "Foreign Trade and Logistics Advisory",
  "homepage.carousel.title3": "Schedule a Visit",
  "homepage.carousel.subtitle3": "Come find the advice and solution you need",
  "homepage.clock1": "Brasilia",
  "homepage.clock2": "London",
  "homepage.clock3": "Washington",
  "homepage.clock4": "Pequim",
  "homepage.clock5": "Joanesburgo",
  "homepage.card1.title": "Mission",
  "homepage.card1.text": "Meet the needs of our customers.",
  "homepage.card2.title": "Values",
  "homepage.card2.text":
    "Faith, commitment, ethics, transparency, compassion, integrity, truth, respect, humility and competence.",
  "homepage.card3.title": "Vision",
  "homepage.card3.text": "Be a reference through the quality of our services.",
  "homepage.cotacao.title": "Quotation of the day",
  "homepage.cotacao.col1": "Real to",
  "homepage.cotacao.col1.linha1": "Dollar",
  "homepage.cotacao.col1.linha2": "Euro",
  "homepage.cotacao.col2": "Multiply by",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "institucional.title": "Fluctus Group",
  "institucional.card1.title": "Fluctus Trading",
  "institucional.card1.subtitle": "Foreign Trade Division",
  "institucional.card1.text": "It is a company focused on advising and consulting in foreign trade.",
  "institucional.card2.title": "Fluctus Supplier Search",
  "institucional.card2.subtitle": "Supplier Search Division",
  "institucional.card2.text":
    "Specializes in finding suppliers outside the country, monitoring and advising customers who need assistance and personalized service.",
  "institucional.card3.title": "Fluctus 3D Print",
  "institucional.card3.subtitle": "Print Division",
  "institucional.card3.text":
    "It is a division specialized in tax advisory, which seeks to offer the client, always the most economical, efficient and correct way to the success of your company.",
  "institucional.card4.title": "Fluctus Log",
  "institucional.card4.subtitle": "Logistics Division",
  "institucional.card4.text":
    "It is a division focused on the storage and distribution of products to serve its customers nationwide.",
  "institucional.card5.title": "Fluctus Store",
  "institucional.card5.subtitle": "Store Division",
  "institucional.card5.text":
    "It is a virtual store, focused on the trade of national and imported products, which has a qualified team to serve its customers throughout the national territory.",
  "institucional.card6.title": "Fluctus Relax",
  "institucional.card6.subtitle": "Express massage division",
  "institucional.card6.text":
    "It is a division specializing in the administration of rapid massage equipment, which is distributed in various parts of the country.",
  "institucional.card7.title": "Fluctus 3D Print",
  "institucional.card7.subtitle": "Custom 3D Products Division",
  "institucional.card7.text": "It is a division specializing in the production of products made with a 3D printer, providing its customers with satisfaction and agility in having the desired product, in an economical, efficient and personalized way.",
  "institucional.card.button": "See more",
  "institucional.section.title": "COMPANY",
  "institucional.section.text":
    "Fluctus Group consists of five divisions that operate in different but interconnected segments, which are recognized in the market for the high credibility and transparency of their services. It supports companies from various segments, from consulting for import and export processes to services related to the corporate world of Foreign Trade.",
  "institucional.section.text2":
    "We always seek to present agility, efficiency and objectivity, which are fundamental factors in each point of the advisory services developed. Our main objective is to reduce costs as much as possible, in the fiscal, administrative, commercial or logistic areas.",
  "institucional.section2.title": "GOAL",
  "institucional.section2.text":
    "Our goal is to serve all customers immediately and in a personalized manner, providing more convenience and satisfaction. We work with focus and commitment to comply with the rules in force in various sectors, tirelessly seeking to avoid obstacles and to present with excellence the best solutions for our customers.",
  "institucional.section3.title": "FLUCTUS",
  "institucional.section3.text":
    "The word Fluctus comes from Latin, meaning “Land covered by the flow of liquid, used on Venus, Io, Titan and Mars” also known as “Onda” in Portuguese, or “Wave” in english.",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "servicos.title": "What we do",
  "servicos.list.trading.title": "FLUCTUS TRADING",
  "servicos.list.trading.item1": "Import and export cost forecast",
  "servicos.list.trading.item2": "Import on behalf of third parties",
  "servicos.list.trading.item3": "Import by order",
  "servicos.list.trading.item4": "Financing of imports and exports from own resources",
  "servicos.list.trading.item5": "Export",
  "servicos.list.trading.item6": "Special or atypical arrangements (temporary admission and customs warehouse)",
  "servicos.list.trading.item7":
    "Outsourcing of activities related to Foreign Trade, generating cost reduction and offering greater efficiency to the customer",
  "servicos.list.trading.item8": "Issuance and control of import licenses",
  "servicos.list.trading.item9": "Documentary analysis prior to boarding",
  "servicos.list.trading.item10": "Labels and tags analysis",
  "servicos.list.trading.item11": "Nationalization of MERCOSUR goods on land borders",
  "servicos.list.trading.item12": "Secure door-to-door insurance",
  "servicos.list.trading.item13": "Suitability of the product to be imported",
  "servicos.list.trading.item14": "Customs clearance",
  "servicos.list.trading.item15": "Register on MAPA (Ministry of Agriculture and Supply, like FDA in USA)",
  "servicos.list.trading.item16": "ANVISA Registration (National Health Surveillance Agency, like FDA in USA)",
  "servicos.list.trading.item17":
    "INMETRO Registration (National Institute of Metrology, Standardization and Industrial Quality, like American National Standards Institute (ANSI)",
  "servicos.list.trading.item18":
    "ANATEL Registration (National Telecommunications Agency, like Federal Communications Commission in USA)",
  "servicos.list.trading.item19": "SISPROM Registration (Promotion Information Registration System)",
  "servicos.list.trading.item20":
    "SISCOSERV Registration (The Integrated Foreign Trade Services System, Like Mdic in USA)",
  "servicos.list.trading.item21": "Hot and Cold Drink Registration",
  "servicos.list.trading.item22": "Shipment tracking from collection to final destination",

  "servicos.list.supplier.title": "Fluctus Supplier Search",
  "servicos.list.supplier.item1": "Supplier Search Department",
  "servicos.list.supplier.item2": "Accompanying clients in domestic and international travel.",
  "servicos.list.supplier.item3": "Assistance in choosing suppliers inside and outside the country",
  "servicos.list.supplier.item4":
    "Support from abroad accompanied by resident and environmentally aware professionals.",

  "servicos.list.tax.title": "Fluctus 3D Print",
  "servicos.list.tax.item1": "Tax Technical Advice",
  "servicos.list.tax.item2": "Tax Cuts",
  "servicos.list.tax.item3": "Drawback Regime",

  "servicos.list.log.title": "Fluctus Log",
  "servicos.list.log.item1": "Freight Forwarding",
  "servicos.list.log.item2": "International and national freight with competitive value and differentiated free time.",
  "servicos.list.log.item3": "Transportation (Sea, Air, Road, Rail)",
  "servicos.list.log.item4": "Road transport of fractional cargo.",
  "servicos.list.log.item5": "Cargo storage monitoring (Customs and General Warehouse)",
  "servicos.list.log.item6": "Separation and Distribution Services",

  "servicos.list.commerce.title": "Fluctus Store",
  "servicos.list.commerce.item1": "Marketing of domestic and imported goods through electronic store.",
  "servicos.list.commerce.item2": "Assistance in selling your product.",

  "servicos.list.relax.title": "Fluctus Relax",
  "servicos.list.relax.item1": "Administration of rapid massage equipment",
  "servicos.list.relax.item2": "Personalized service in corporate environments",

  "servicos.list.events.title": "Fluctus Events",
  "servicos.list.events.item1": "Organization of corporate events",
  "servicos.list.events.item2": "Structures (Box in wood and aluminum)",
  "servicos.list.events.item3": "Graphic design, presentation creation and editing, video and photo editing",
  "servicos.list.events.item4": "Multimedia Production",
  "servicos.list.events.item5": "Receptionists and Promoters",
  "servicos.list.events.item6": "Multilingual Professional Translators",
  "servicos.list.events.item7": "Lighting",
  "servicos.list.events.item8": "Sound",
  "servicos.list.events.item9": "High definition projectors, projection screens",

  "servicos.paragraph":
    "Faith, commitment, ethics, transparency, compassion, integrity, truth, respect, humility and competence.",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  "utilitarios.title": "Utilities",

  //accordion conversao
  "utilitarios.accordion.conversao.name": "CONVERSION FACTORS",
  "utilitarios.accordion.conversao.table1.title": "Length",
  "utilitarios.accordion.conversao.table1.col1": "Conversion",
  "utilitarios.accordion.conversao.table1.col2": "Multiply by",
  "utilitarios.accordion.conversao.table1.item1": "Inches to centimeters",
  "utilitarios.accordion.conversao.table1.item2": "Centimeters to Inches",
  "utilitarios.accordion.conversao.table1.item3": "Inches to Millimeters",
  "utilitarios.accordion.conversao.table1.item4": "Millimeters to Inches",
  "utilitarios.accordion.conversao.table1.item5": "Feet to Meters",
  "utilitarios.accordion.conversao.table1.item6": "Meters to Feet",
  "utilitarios.accordion.conversao.table1.item7": "Miles to kilometers",
  "utilitarios.accordion.conversao.table1.item8": "kilometers to Miles",
  "utilitarios.accordion.conversao.table1.item9": "CONVERSION",

  "utilitarios.accordion.conversao.table2.title": "Volume",
  "utilitarios.accordion.conversao.table2.item1": "Cubic Inches to Cubic Centimeters",
  "utilitarios.accordion.conversao.table2.item2": "Cubic Centimeters to Cubic Inches",
  "utilitarios.accordion.conversao.table2.item3": "Cubic Feet to Cubic Meters",
  "utilitarios.accordion.conversao.table2.item4": "Cubic Meters to Cubic Feet",
  "utilitarios.accordion.conversao.table2.item5": "US Gallons to Liters",
  "utilitarios.accordion.conversao.table2.item6": "Liters to US Gallons",

  "utilitarios.accordion.conversao.table3.title": "Volume",
  "utilitarios.accordion.conversao.table3.item1": "Pounds to Kilograms",
  "utilitarios.accordion.conversao.table3.item2": "Kilograms to Pounds",
  "utilitarios.accordion.conversao.table3.item3": "Long Tons to Metric Tons",
  "utilitarios.accordion.conversao.table3.item4": "Metric Tons to Tons",
  "utilitarios.accordion.conversao.table3.item5": "Short Tons to Metric Tons",
  "utilitarios.accordion.conversao.table3.item6": "Metric Tons to Short Tons",

  "utilitarios.accordion.conversao.table4.title": "Temperature",
  "utilitarios.accordion.conversao.table4.col2": "Formula",
  "utilitarios.accordion.conversao.table4.item1": "Centigrade degrees to Fahrenheit",
  "utilitarios.accordion.conversao.table4.item2": "Kilograms to Pounds",
  "utilitarios.accordion.conversao.table4.item3": "multiply by 1.8 and add 32",
  "utilitarios.accordion.conversao.table4.item4": "subtract 32 and multiply by 0.56",

  //accordion containers
  "utilitarios.accordion.containers.name": "CONTAINER TYPES",
  "utilitarios.accordion.containers.table.col1": "Dimensions",
  "utilitarios.accordion.containers.table.col2": "Width (m)",
  "utilitarios.accordion.containers.table.col3": "Length (m)",
  "utilitarios.accordion.containers.table.col4": "Height (m)",
  "utilitarios.accordion.containers.table.linha1": "External",
  "utilitarios.accordion.containers.table.linha2": "Internal",
  "utilitarios.accordion.containers.table.linha3": "Door",
  "utilitarios.accordion.containers.table2.col1": "Cubic Cap. (M3)",
  "utilitarios.accordion.containers.table2.col2": "Load Cap (ton)",
  "utilitarios.accordion.containers.table2.col3": "Tare (ton)",

  //accordion calculo
  "utilitarios.accordion.maritimo.name": "MARITIME CALCULATION",
  "utilitarios.accordion.maritimo.text": "In maritime transport, by convention 1 tonne is equal to 1m³. Example:",
  "utilitarios.accordion.maritimo.table.col1": "Type",
  "utilitarios.accordion.maritimo.table.col2": "Gross weight",
  "utilitarios.accordion.maritimo.table.col3": "Cubed",
  "utilitarios.accordion.maritimo.table.col4": "Cubed Weight",
  "utilitarios.accordion.maritimo.table.linha1": "Cylinder",
  "utilitarios.accordion.maritimo.table.linha2": "Box",
  "utilitarios.accordion.maritimo.paragraph":
    "* In the case of shipping, the account is even simpler. It is always considered that 1 cubic meter is equal to 1 ton. In the case of the box will be charged the minimum fare, which can vary between ½ and 1 ton, according to the shipping company to be used. In the case of the cylinder, the chargeable weight will be 2.10 tons, although the cargo has a gross weight of 1.5 tons.",

  //accordion uteis
  "utilitarios.accordion.uteis.name": "USEFUL LINKS",
  "utilitarios.accordion.uteis.title1": "Federal Institutions",
  "utilitarios.accordion.uteis.title2": "Major Sea Ports of Brazil",
  "utilitarios.accordion.uteis.col1.item1":
    "ABNT – Associação Brasileira de Normas Técnicas (Brazilian Association of Technical Standards)",
  "utilitarios.accordion.uteis.col1.item2":
    "ANTAQ – Agência Nacional de Transporte Aquaviários (National Waterway Transportation Agency)",
  "utilitarios.accordion.uteis.col1.item3": "Internationals",
  "utilitarios.accordion.uteis.col1.item4":
    "ANTT – Agência Nacional de Transportes Terrestres (National Land Transportation Agency)",
  "utilitarios.accordion.uteis.col1.item5":
    "CNT – Confederação Nacional do Transporte (National Confederation of Transport)",
  "utilitarios.accordion.uteis.col1.item6": "Government of Brazil",
  "utilitarios.accordion.uteis.col1.item7":
    "IBGE – Instituto Brasileiro de Geografia e Estatística (Brazilian Institute of Geography and Statistics)",
  "utilitarios.accordion.uteis.col1.item8": "Receita Federal (IRS)",
  "utilitarios.accordion.uteis.col1.item9": "Planning Ministry",
  "utilitarios.accordion.uteis.col1.item10": "Ministry of Transport",
  "utilitarios.accordion.uteis.col1.item11": "Ministry of Economy Industry, Foreign Trade and Services",
  "utilitarios.accordion.uteis.col2.item1": "Espírito Santo Docks Company / Victoria Harbor",
  "utilitarios.accordion.uteis.col2.item2": "Imbituba Port",
  "utilitarios.accordion.uteis.col2.item3": "Port of Itajai",
  "utilitarios.accordion.uteis.col2.item4": "Port of Itapoá",
  "utilitarios.accordion.uteis.col2.item5": "Port of Navegantes",
  "utilitarios.accordion.uteis.col2.item6": "Recife Harbor",
  "utilitarios.accordion.uteis.col2.item7": "Rio Grande Harbor",
  "utilitarios.accordion.uteis.col2.item8": "Suape Harbor",
  "utilitarios.accordion.uteis.col2.item9": "Port of Sao Francisco do Sul",
  "utilitarios.accordion.uteis.col2.item10": "Port of Santos",

  //accordion glossario
  "utilitarios.accordion.glossario.name": "GLOSSARY",

  "utilitarios.accordion.glossario.definicao.item1": "Cubic Space Allowed",
  "utilitarios.accordion.glossario.definicao.item2": "Notification related to something received.",
  "utilitarios.accordion.glossario.definicao.item3":
    "Insurance fee charged on certain freight or customs fees in proportion to the total value of the transaction products (Invoice).",
  "utilitarios.accordion.glossario.definicao.item4":
    "Special customs regime that allows the importation of goods that must remain in the country for a fixed period, with total or partial suspension of taxes.",
  "utilitarios.accordion.glossario.definicao.item5":
    "Articles Dangereux de Route ou Transporte de Artigos Perigosos (Route Dangereux Articles or Transport of Dangerous Goods).",
  "utilitarios.accordion.glossario.definicao.item6":
    "Congregation of countries that aim to establish a Latin American common market, consisting of: Argentina, Bolivia, Brazil, Chile, Colombia, Cuba, Ecuador, Mexico, Paraguay, Peru, Uruguay and Venezuela.",
  "utilitarios.accordion.glossario.definicao.item7":
    "Congregation of countries that aim to establish Congregation of countries that aim to establish a Latin American common market, consisting of: Argentina, Bolivia, Brazil, Chile, Colombia, Cuba, Ecuador, Mexico, Paraguay, Peru, Uruguay and Venezuelan Common Market, formed by: Argentina, Bolivia, Brazil, Chile, Colombia, Cuba, Ecuador, Mexico, Paraguay, Peru, Uruguay and Venezuela.",
  "utilitarios.accordion.glossario.definicao.item8":
    "Goods represented by quantity, fragments or parts, strictly necessary to make known their nature, species and quality.",
  "utilitarios.accordion.glossario.definicao.item9":
    "Covered place, where materials / products are received, classified, stored and shipped.",
  "utilitarios.accordion.glossario.definicao.item10": "Air Waybill or Air Transport Knowledge.",
  "utilitarios.accordion.glossario.definicao.item11": "Bill of lading",
  "utilitarios.accordion.glossario.definicao.item12":
    "Consolidation of a single shipment into a Master Air Waybill (MAWB) comprising a House Air Waybill (HAWB) issued by a shipper (HAWB).",
  "utilitarios.accordion.glossario.definicao.item13":
    "For the purpose of tax treatment, new or used goods, intended for the personal use or consumption of the traveler, in accordance with the circumstances of his travel, are considered as baggage.",
  "utilitarios.accordion.glossario.definicao.item14":
    "Safe harbor location where ships are awaiting authorization to dock at the available or designated dock",
  "utilitarios.accordion.glossario.definicao.item15":
    "It is the monolithic assembly formed by the body with the set of two axles and at least four wheels. It is coupled to the truck body for transportation, forming a set of two bodies pulled by one truck.",
  "utilitarios.accordion.glossario.definicao.item16": "Left side of the ship.",
  "utilitarios.accordion.glossario.definicao.item17": "Bonded Warehouse.",
  "utilitarios.accordion.glossario.definicao.item18":
    "Expression of maritime transport meaning general or fractional cargo.",
  "utilitarios.accordion.glossario.definicao.item19": "Companies specializing in brokering maritime charter.",
  "utilitarios.accordion.glossario.definicao.item20": "Bulk cargo, or without packaging.",
  "utilitarios.accordion.glossario.definicao.item21": "Bulk carrier, ie suitable for the carriage of bulk cargo.",
  "utilitarios.accordion.glossario.definicao.item22": "Bulk container, ie suitable for the carriage of bulk cargo.",
  "utilitarios.accordion.glossario.definicao.item23": "Domestic navigation (by the coast of the country).",
  "utilitarios.accordion.glossario.definicao.item24":
    "Equipment used in ports to lift large heavy loads or construction materials, and consists of 3 stiff pellets attached to the top where they receive a pulley where the cable passes.",
  "utilitarios.accordion.glossario.definicao.item25":
    "Expression of shipping, which means the depth at which each ship is submerged in water. Technically is the distance from the water slide to the ship's keel.",
  "utilitarios.accordion.glossario.definicao.item26":
    "It is the service usually used in ports, where self-employed professionals perform the work of cargo handling.",
  "utilitarios.accordion.glossario.definicao.item27": "It's a closed cart.",
  "utilitarios.accordion.glossario.definicao.item28":
    "It is a closed trailer, with thermal insulation in its walls, that conserves the temperature of the load.",
  "utilitarios.accordion.glossario.definicao.item29":
    "It is the monolithic assembly formed by the cab, engine and drive wheels of the truck. Can be attached to various types of trailers and semi-trailers for transport.",
  "utilitarios.accordion.glossario.definicao.item30":
    "Reciprocal Payments and Credits Agreement - Central Bank Controlled Payments System covering ALADI countries and the Dominican Republic.",
  "utilitarios.accordion.glossario.definicao.item31": "Cost and Freight.",
  "utilitarios.accordion.glossario.definicao.item32": "Proof of Import.",
  "utilitarios.accordion.glossario.definicao.item33":
    "charged on oil and derivatives. CIF - Cost, Insurance and Freight. In this case, the quoted material already has everything embedded in the price, that is, it is put at destination.",
  "utilitarios.accordion.glossario.definicao.item34": "Cariage and Insurance Paid To.",
  "utilitarios.accordion.glossario.definicao.item35":
    "Set of vehicles that go together to the same destination. Mainly used for safety reasons; ammunition and food carts accompanying military forces; railway composition (in Portugal).",
  "utilitarios.accordion.glossario.definicao.item36":
    "Document issued by the carrier, based on the invoice data, which informs the freight value and accompanies the cargo. The recipient signs the receipt in one of the following ways.",
  "utilitarios.accordion.glossario.definicao.item37":
    "Consignee. Natural or legal person indicated on the transport document who has the right to claim the goods to the carrier at destination. For legal purposes, the owner of the cargo is presumed.",
  "utilitarios.accordion.glossario.definicao.item38":
    "It consists of creating large uploads from several smaller ones. Results in economies of scale in freight costs.",
  "utilitarios.accordion.glossario.definicao.item39":
    "Metal equipment in the shape of a large box, which is used to transport various materials, thus unitizing loads, which when stored inside, do not suffer damage during the journey or in case of transhipment to other modes.",
  "utilitarios.accordion.glossario.definicao.item40": "First deck area of ​​ship.",
  "utilitarios.accordion.glossario.definicao.item41": "Commerce Planning Colaboration.",
  "utilitarios.accordion.glossario.definicao.item42": "Cariage Paid To or Paid Transportation Until.",
  "utilitarios.accordion.glossario.definicao.item43":
    "Cubic volume available to store or transport. The cubic meter is calculated by multiplying the length by the width and the height.",
  "utilitarios.accordion.glossario.definicao.item44":
    "It is the sum of the transportation cost, the storage cost and the inventory maintenance cost.",
  "utilitarios.accordion.glossario.definicao.item45":
    "Special customs regime that allows the deposit, in the internal market, under customs, of products already traded abroad, considered exported for all fiscal, credit and exchange effects.",
  "utilitarios.accordion.glossario.definicao.item46": "Delivered At Frontier.",
  "utilitarios.accordion.glossario.definicao.item47": "Delivered Duty Paid.",
  "utilitarios.accordion.glossario.definicao.item48": "Delivered Duty Unpaid.",
  "utilitarios.accordion.glossario.definicao.item49": "Deadline for delivery of cargo for shipment.",
  "utilitarios.accordion.glossario.definicao.item50":
    "Penalty determined by contract to be paid by the contractor of a ship when it takes longer than agreed in the ports of embarkation or discharge. The term is also used for per diems to be paid by the importer after the period of free stay granted by the freight agent / shipowner.",
  "utilitarios.accordion.glossario.definicao.item51":
    "Delivered Ex QUAY or delivery at the pier. The seller delivers the goods to the dock of the destination port.",
  "utilitarios.accordion.glossario.definicao.item52": "Delivered Ex SHIP.",
  "utilitarios.accordion.glossario.definicao.item53":
    "Basic document of the importation order that must contain the identification of the importer, the classification, the customs value and the origin of the goods, among other information.",
  "utilitarios.accordion.glossario.definicao.item54":
    "It is the intermediate place where the goods are between shipping and transportation (various modes) in order to facilitate and expedite the loading and unloading operation.",
  "utilitarios.accordion.glossario.definicao.item55":
    "Excavation service in port access channels and mooring areas for maintenance or depth enhancement.",
  "utilitarios.accordion.glossario.definicao.item56":
    "Involves the importation of non-tax components (IPI, ICMS, Freight Additional for Merchant Marine Renewal and State Transport Service Tax), linked to an export commitment.",
  "utilitarios.accordion.glossario.definicao.item57": "Simplified Export Declaration.",
  "utilitarios.accordion.glossario.definicao.item58": "Simplified Import Declaration.",
  "utilitarios.accordion.glossario.definicao.item59": "Interior Customs Station.",
  "utilitarios.accordion.glossario.definicao.item60":
    "Economic Logistic Quantity. It is the quantity that minimizes the logistic cost.",
  "utilitarios.accordion.glossario.definicao.item61":
    "Appropriate wrap applied directly to the product for protection and preservation until consumption / end use.",
  "utilitarios.accordion.glossario.definicao.item62":
    "Special regime permitting the importation of foreign goods for storage in a customs bonded place for public use, with suspension of payment of taxes.",
  "utilitarios.accordion.glossario.definicao.item63":
    "Special regime that allows the company to import with suspension of payment of taxes, under computerized control, goods to be submitted to the operation of industrialization and subsequent exportation.",
  "utilitarios.accordion.glossario.definicao.item64": "Right side of the ship.",
  "utilitarios.accordion.glossario.definicao.item65": "Dock employee working on loading and unloading of ships.",
  "utilitarios.accordion.glossario.definicao.item66": "Expression of shipping, which means day of berthing (arrival).",
  "utilitarios.accordion.glossario.definicao.item67": "Expression of shipping, meaning departure day.",
  "utilitarios.accordion.glossario.definicao.item68":
    "Output of products from the country, for a specified period of time, with suspension of payment of taxes, including exportation, subject to their re-importation in the state in which they were exported.",
  "utilitarios.accordion.glossario.definicao.item69": "Ex Works.",
  "utilitarios.accordion.glossario.definicao.item70":
    "Free Alongside Ship. The seller delivers the goods to the buyer on the ship's side at the port of shipment.",
  "utilitarios.accordion.glossario.definicao.item71":
    "Free Carrier. Seller is exempt from liability when delivering goods to buyer-appointed agent or carrier.",
  "utilitarios.accordion.glossario.definicao.item72": "Full Container Load.",
  "utilitarios.accordion.glossario.definicao.item73": "Forwarder Certificate of Receipt.",
  "utilitarios.accordion.glossario.definicao.item74": "Hub port maritime feeding service or cargo distribution.",
  "utilitarios.accordion.glossario.definicao.item75": "Supply Ships.",
  "utilitarios.accordion.glossario.definicao.item76":
    "Free In and Out ou isento de taxas no embarque e no desembarque. Despesas de embarque são do exportador e as de desembarque do importador. Nada de responsabilidade do Armador.",
  "utilitarios.accordion.glossario.definicao.item77":
    "Free On Board or Free Shipping Included (on board). There are some variations of FOB. It can be FOB Factory when material has to be removed and FOB City when the supplier places the material in a carrier chosen by the customer.",
  "utilitarios.accordion.glossario.definicao.item78":
    "Freight collect, ie payable. Can be paid anywhere, but as a rule is paid at destination.",
  "utilitarios.accordion.glossario.definicao.item79": "Freight Forwarder.",
  "utilitarios.accordion.glossario.definicao.item80":
    "Similar to freight collect, however, can only be paid at destination.",
  "utilitarios.accordion.glossario.definicao.item81":
    "Prepaid Freight or Freight Paid. Freight, as a rule, is paid at origin when the goods are shipped.",
  "utilitarios.accordion.glossario.definicao.item82": "Air Waybill issued by Cargo Consolidator.",
  "utilitarios.accordion.glossario.definicao.item83":
    "It is the potential cargo generator of the port or its land area of ​​influence. Hinterland basically depends on the development potential of the region where the port is located and on land and feeder transportation costs.",
  "utilitarios.accordion.glossario.definicao.item84":
    "Expression used for container, indicating that it will be ovulated or spawned in the shipper's or consignee's house.",
  "utilitarios.accordion.glossario.definicao.item85": "Maritime bill issued by a freight forwarder.",
  "utilitarios.accordion.glossario.definicao.item86":
    "Center point for collecting, sorting and distributing to a specific area or region.",
  "utilitarios.accordion.glossario.definicao.item87": "Intermediate Bulk Container or Intermediate Bulk Container.",
  "utilitarios.accordion.glossario.definicao.item88":
    "acronym that identifies the 13 terms that standardize the language used in the export and import market.",
  "utilitarios.accordion.glossario.definicao.item89":
    "is to serve the internal or external customer at the exact moment of their need, with the quantities necessary for the operation / production, thus avoiding the maintenance of larger stocks.",
  "utilitarios.accordion.glossario.definicao.item90":
    "continuous improvement process with common sense and low investments.",
  "utilitarios.accordion.glossario.definicao.item91":
    "Letter of Credit abbreviation, LLP - Leading Logistics Provider or Leading Logistics Service Provider.",
  "utilitarios.accordion.glossario.definicao.item92":
    "maritime transport means water that is put into the hold to give weight and balance to the ship when it is unloaded; by rail means a layer of permeable substances such as sand, gravel or crushed stone lying on the railway bed and on which sleepers rest.",
  "utilitarios.accordion.glossario.definicao.item93":
    "ship's stay at the port, which means the expected time for the operation to take place (docking, loading and set off).",
  "utilitarios.accordion.glossario.definicao.item94":
    "These are all movement and storage activities, which facilitate the flow of products from the point of purchase of raw material to the point of final consumption, as well as the information flows that put products in motion for the purpose. provide appropriate customer service levels at a reasonable cost. (Definition of Ronald H. Ballou in his book “Business Logistics”).",
  "utilitarios.accordion.glossario.definicao.item95":
    "In the market is considered as the path that the packaging takes after the delivery of materials, towards the recycling of them. Never going back to the origin.",
  "utilitarios.accordion.glossario.definicao.item96": "Air Waybill issued by the carrier to the cargo consolidator.",
  "utilitarios.accordion.glossario.definicao.item97":
    "They are instruments used to evaluate the performance of any logistics activity. These may be reports, audits, etc. You can't improve what we don't measure.",
  "utilitarios.accordion.glossario.definicao.item98":
    "Aims economic and trade integration between its partners - Argentina, Brazil, Paraguay and Uruguay",
  "utilitarios.accordion.glossario.definicao.item99":
    "consists of searching for the product (s) directly from the supplier (s), on a scheduled basis, to meet their supply needs.",
  "utilitarios.accordion.glossario.definicao.item100":
    "are the existing types / means of transport. They are: maritime: (made by sea), rail (made by rail), road (made by road), waterway (made in rivers), pipeline (made by pipelines) and air (made by air).",
  "utilitarios.accordion.glossario.definicao.item101":
    "Non-Vessel-Operating Common Carrier. Non-Vessel Shipping Operator.",
  "utilitarios.accordion.glossario.definicao.item102": "Multimodal Transport Operator.",
  "utilitarios.accordion.glossario.definicao.item103": "Factory flows to dealer.",
  "utilitarios.accordion.glossario.definicao.item104": "List of goods or volumes.",
  "utilitarios.accordion.glossario.definicao.item105":
    "Securing loads on board the vehicle or container using various materials.",
  "utilitarios.accordion.glossario.definicao.item106": "back of the ship.",
  "utilitarios.accordion.glossario.definicao.item107":
    "It is part of the port operating rules and means the minimum established tonnage to be operated within a period of six hours.",
  "utilitarios.accordion.glossario.definicao.item108": "Pre-stacking containers to prepare them for shipment.",
  "utilitarios.accordion.glossario.definicao.item109": "front of the ship.",
  "utilitarios.accordion.glossario.definicao.item110": "Pre-shipment inspection acronym.",
  "utilitarios.accordion.glossario.definicao.item111":
    "Computerized record elaborated in Siscomex that combines in a single document the commercial, financial, exchange and tax information related to an export.",
  "utilitarios.accordion.glossario.definicao.item112":
    "Small steam used to tow or maneuver ships safely in port areas.",
  "utilitarios.accordion.glossario.definicao.item113":
    "It is the monolithic assembly formed by the body with the set of two axles and at least four wheels. It is coupled to the truck body for transportation, forming a set of two bodies pulled by one truck.",
  "utilitarios.accordion.glossario.definicao.item114":
    "These are places thus declared by the competent customs authority, in the primary or secondary zone, so that they may occur there under customs control, movement, storage and customs clearance.",
  "utilitarios.accordion.glossario.definicao.item115":
    "Industrial Warehouse Regime under Computerized Customs Control - Allows the accredited company to import tax-exempt inputs under customs control for the industrial production of goods to be exported.",
  "utilitarios.accordion.glossario.definicao.item116": "Special Enclosure for Export Customs Clearance.",
  "utilitarios.accordion.glossario.definicao.item117":
    "It means bringing back merchandise that has been temporarily exported back to the country.",
  "utilitarios.accordion.glossario.definicao.item118":
    "Special Customs Export and Import Regime of Goods Destined for Exploration and Mining Activities of Oil and Natural Gas Reserves - Allows the export with fictive exit of the customs territory and subsequent application of the temporary admission regime, as well as parts and as well as import under the drawback regime.",
  "utilitarios.accordion.glossario.definicao.item119":
    "Also called Simplex, it gathers in a single document the elements of an export worth up to ten thousand dollars or its equivalent in other currencies.",
  "utilitarios.accordion.glossario.definicao.item120":
    "It is the monolithic assembly formed by the body with one axle and wheels. It is coupled to the mechanical horse or tractor for transport, or even used as a trailer when it is coupled to a dolly. It is widely used to transport sugar cane.",
  "utilitarios.accordion.glossario.definicao.item121":
    "It is a program in developed countries for full or partial exemption from import tax on products purchased from developing countries.",
  "utilitarios.accordion.glossario.definicao.item122": "Maritime Agent.",
  "utilitarios.accordion.glossario.definicao.item123":
    "In most cases it is the Beneficiary himself. Unless otherwise stated in the Credit, it may be a third party. It is the same as Consignator.",
  "utilitarios.accordion.glossario.definicao.item124":
    "truck body type, which has retractable tarpaulins on its sides.",
  "utilitarios.accordion.glossario.definicao.item125":
    "Logistics Information System that provides the information specifically needed for logistical administration support at all hierarchical levels.",
  "utilitarios.accordion.glossario.definicao.item126":
    "Computerized system and controlled by government agencies involved in foreign trade, which control the activities of registration, monitoring and control of operations.",
  "utilitarios.accordion.glossario.definicao.item127": "Additional fee charged beyond normal shipping.",
  "utilitarios.accordion.glossario.definicao.item128": "Supply Chain Management.",
  "utilitarios.accordion.glossario.definicao.item129":
    "Difference between gross and net weights representing merchandise packaging, empty container weight, or empty vehicle weight.",
  "utilitarios.accordion.glossario.definicao.item130": "Common External Tariff.",
  "utilitarios.accordion.glossario.definicao.item131": "Twenty Foot Equivalent Unit. 20ft standard container size.",
  "utilitarios.accordion.glossario.definicao.item132": "Tons per working kilometer.",
  "utilitarios.accordion.glossario.definicao.item133": "Transportation Management Systems.",
  "utilitarios.accordion.glossario.definicao.item134":
    "Transfer goods / products from one mode of transport to another over the course of the delivery operation.",
  "utilitarios.accordion.glossario.definicao.item135":
    "Special customs regime that allows the transportation of goods in the national territory, under customs control, from one point to another of the customs territory, with suspension of payment of taxes.",
  "utilitarios.accordion.glossario.definicao.item136":
    "It is the integration of services of more than one mode of transport, with issuance of independent documents, where each carrier assumes responsibility for its transport.",
  "utilitarios.accordion.glossario.definicao.item137":
    "It is the integration of the services of more than one mode of transport, used for a certain cargo to travel the path between the sender and its recipient, between the various existing modes, being issued only a single bill of lading by the sole responsible for transport, which is OTM - Multimodal Transport Operator.",
  "utilitarios.accordion.glossario.definicao.item138":
    "Transhipment. Unloading and reloading of goods during the course of travel, always using the same means of transport. In the SWIFT MT 700 message, it indicates whether transhipment is prohibited or permitted.",
  "utilitarios.accordion.glossario.definicao.item139":
    "is a motorized hang glider that comes equipped with wheels and / or floats and fiberglass seats.",
  "utilitarios.accordion.glossario.definicao.item140":
    "Truck that has a double axle in the body, that is, 2 axles together. The goal is to support more weight and provide better vehicle performance.",
  "utilitarios.accordion.glossario.definicao.item141":
    "is to aggregate several smaller packages or packages into a larger unit load.",
  "utilitarios.accordion.glossario.definicao.item142":
    "Document issued by the DMM (Department of Merchant Marine), so that prescribed goods, that is, those imported with governmental benefits, can be transported on a foreign ship in the absence of a national flagged ship.",
  "utilitarios.accordion.glossario.definicao.item143": "Warehouse Control Systems or Warehouse Control Systems.",
  "utilitarios.accordion.glossario.definicao.item144":
    "It is the fee charged by the administration of a port for its use in operations involving docking, loading, unloading and stocking at the docks and warehouses attached to the port.",
  "utilitarios.accordion.glossario.definicao.item145": "Warehouse Management Systems.",
  "utilitarios.accordion.glossario.definicao.item146":
    "Area demarcated by customs at customs ports, airports and customs border points.",
  "utilitarios.accordion.glossario.definicao.item147":
    "It comprises the customs territory, excluding the primary zone, including territorial waters and airspace.",

  "utilitarios.accordion.glossario.termo.item1": "ACF – Attainable Cubic Feet",
  "utilitarios.accordion.glossario.termo.item2": "Acknowledgement of Receipt",
  "utilitarios.accordion.glossario.termo.item3": "AD Valorem",
  "utilitarios.accordion.glossario.termo.item4": "Temporary Admission",
  "utilitarios.accordion.glossario.termo.item5": "ADR",
  "utilitarios.accordion.glossario.termo.item6": "AFRMM",
  "utilitarios.accordion.glossario.termo.item7": "Aladi (Latin American Integration Association)",
  "utilitarios.accordion.glossario.termo.item8": "Samples without commercial value",
  "utilitarios.accordion.glossario.termo.item9": "Warehouse",
  "utilitarios.accordion.glossario.termo.item10": "AWB",
  "utilitarios.accordion.glossario.termo.item11": "B/L (Bill of Lading)",
  "utilitarios.accordion.glossario.termo.item12": "Back to Back",
  "utilitarios.accordion.glossario.termo.item13": "Baggage",
  "utilitarios.accordion.glossario.termo.item14": "Bar",
  "utilitarios.accordion.glossario.termo.item15": "Bi-Train or Trailer",
  "utilitarios.accordion.glossario.termo.item16": "Port",
  "utilitarios.accordion.glossario.termo.item17": "Bonded Warehousing",
  "utilitarios.accordion.glossario.termo.item18": "Break-Bulk",
  "utilitarios.accordion.glossario.termo.item19": "Brokerage Houses",
  "utilitarios.accordion.glossario.termo.item20": "Bulk Cargo",
  "utilitarios.accordion.glossario.termo.item21": "Bulk Carrier",
  "utilitarios.accordion.glossario.termo.item22": "Bulk Container",
  "utilitarios.accordion.glossario.termo.item23": "Cabotage",
  "utilitarios.accordion.glossario.termo.item24": "Head",
  "utilitarios.accordion.glossario.termo.item25": "ship draft",
  "utilitarios.accordion.glossario.termo.item26": "THC",
  "utilitarios.accordion.glossario.termo.item27": "Truck chest",
  "utilitarios.accordion.glossario.termo.item28": "Isothermal trailer",
  "utilitarios.accordion.glossario.termo.item29": "Mechanical horse",
  "utilitarios.accordion.glossario.termo.item30": "CCR",
  "utilitarios.accordion.glossario.termo.item31": "CFR",
  "utilitarios.accordion.glossario.termo.item32": "CI",
  "utilitarios.accordion.glossario.termo.item33": "CIDE (Economic Domain Intervention Contribution)",
  "utilitarios.accordion.glossario.termo.item34": "CIP",
  "utilitarios.accordion.glossario.termo.item35": "Train",
  "utilitarios.accordion.glossario.termo.item36": "Transport knowledge",
  "utilitarios.accordion.glossario.termo.item37": "Consignee",
  "utilitarios.accordion.glossario.termo.item38": "Cargo Consolidation",
  "utilitarios.accordion.glossario.termo.item39": "Container",
  "utilitarios.accordion.glossario.termo.item40": "Deck",
  "utilitarios.accordion.glossario.termo.item41": "CPC",
  "utilitarios.accordion.glossario.termo.item42": "CPT",
  "utilitarios.accordion.glossario.termo.item43": "Cubage",
  "utilitarios.accordion.glossario.termo.item44": "Logistic Cost",
  "utilitarios.accordion.glossario.termo.item45": "DAC (Certified Customs Deposit)",
  "utilitarios.accordion.glossario.termo.item46": "DAF",
  "utilitarios.accordion.glossario.termo.item47": "DDP ou Door to Door",
  "utilitarios.accordion.glossario.termo.item48": "DDU",
  "utilitarios.accordion.glossario.termo.item49": "Dead line",
  "utilitarios.accordion.glossario.termo.item50": "Demurrage",
  "utilitarios.accordion.glossario.termo.item51": "DEQ",
  "utilitarios.accordion.glossario.termo.item52": "DES",
  "utilitarios.accordion.glossario.termo.item53": "DI (Import declaration)",
  "utilitarios.accordion.glossario.termo.item54": "Docas ou Docks",
  "utilitarios.accordion.glossario.termo.item55": "Dredging",
  "utilitarios.accordion.glossario.termo.item56": "Draw-back",
  "utilitarios.accordion.glossario.termo.item57": "DSE",
  "utilitarios.accordion.glossario.termo.item58": "DSI",
  "utilitarios.accordion.glossario.termo.item59": "EADI",
  "utilitarios.accordion.glossario.termo.item60": "ELQ",
  "utilitarios.accordion.glossario.termo.item61": "Package",
  "utilitarios.accordion.glossario.termo.item62": "Customs Warehouse",
  "utilitarios.accordion.glossario.termo.item63": "Industrial warehouse under computer control",
  "utilitarios.accordion.glossario.termo.item64": "Starboard",
  "utilitarios.accordion.glossario.termo.item65": "Docker",
  "utilitarios.accordion.glossario.termo.item66": "ETA",
  "utilitarios.accordion.glossario.termo.item67": "ETS",
  "utilitarios.accordion.glossario.termo.item68": "Temporary export",
  "utilitarios.accordion.glossario.termo.item69": "EXW",
  "utilitarios.accordion.glossario.termo.item70": "FAS",
  "utilitarios.accordion.glossario.termo.item71": "FCA",
  "utilitarios.accordion.glossario.termo.item72": "FCL",
  "utilitarios.accordion.glossario.termo.item73": "FCR",
  "utilitarios.accordion.glossario.termo.item74": "Feeder",
  "utilitarios.accordion.glossario.termo.item75": "Feeder Ship",
  "utilitarios.accordion.glossario.termo.item76": "FIO",
  "utilitarios.accordion.glossario.termo.item77": "FOB",
  "utilitarios.accordion.glossario.termo.item78": "Freight Collect",
  "utilitarios.accordion.glossario.termo.item79": "Freight Forwarders",
  "utilitarios.accordion.glossario.termo.item80": "Freight Payable at Destination",
  "utilitarios.accordion.glossario.termo.item81": "Freight Prepaid ou Freight Paid",
  "utilitarios.accordion.glossario.termo.item82": "HAWB (House Airway Bill)",
  "utilitarios.accordion.glossario.termo.item83": "Hinterland",
  "utilitarios.accordion.glossario.termo.item84": "House (casa)",
  "utilitarios.accordion.glossario.termo.item85": "House Bill of Lading ou House B/L",
  "utilitarios.accordion.glossario.termo.item86": "Hub",
  "utilitarios.accordion.glossario.termo.item87": "IBC",
  "utilitarios.accordion.glossario.termo.item88": "Incoterms",
  "utilitarios.accordion.glossario.termo.item89": "Just-in-Time ou JIT",
  "utilitarios.accordion.glossario.termo.item90": "Kaizen",
  "utilitarios.accordion.glossario.termo.item91": "L/C",
  "utilitarios.accordion.glossario.termo.item92": "Ballast",
  "utilitarios.accordion.glossario.termo.item93": "Layday ou Laytime",
  "utilitarios.accordion.glossario.termo.item94": "Business logistics",
  "utilitarios.accordion.glossario.termo.item95": "Reverse or Reverse Logistics",
  "utilitarios.accordion.glossario.termo.item96": "MAWB (Master Airway Bill)",
  "utilitarios.accordion.glossario.termo.item97": "Performance Measures",
  "utilitarios.accordion.glossario.termo.item98": "Mercosul (Southern Common Market)",
  "utilitarios.accordion.glossario.termo.item99": "Milk Run",
  "utilitarios.accordion.glossario.termo.item100": "Modals",
  "utilitarios.accordion.glossario.termo.item101": "NVOCC",
  "utilitarios.accordion.glossario.termo.item102": "OTM",
  "utilitarios.accordion.glossario.termo.item103": "Outbound",
  "utilitarios.accordion.glossario.termo.item104": "Packing List (Romaneio)",
  "utilitarios.accordion.glossario.termo.item105": "Cleavage",
  "utilitarios.accordion.glossario.termo.item106": "Stern",
  "utilitarios.accordion.glossario.termo.item107": "Loading board",
  "utilitarios.accordion.glossario.termo.item108": "Pre-stacking",
  "utilitarios.accordion.glossario.termo.item109": "Bow",
  "utilitarios.accordion.glossario.termo.item110": "PSI",
  "utilitarios.accordion.glossario.termo.item111": "RE (Export registration)",
  "utilitarios.accordion.glossario.termo.item112": "Tug",
  "utilitarios.accordion.glossario.termo.item113": "Tow or bi-train",
  "utilitarios.accordion.glossario.termo.item114": "Customs Enclosures",
  "utilitarios.accordion.glossario.termo.item115": "Recof",
  "utilitarios.accordion.glossario.termo.item116": "REDEX",
  "utilitarios.accordion.glossario.termo.item117": "Reimportation",
  "utilitarios.accordion.glossario.termo.item118": "Repetro",
  "utilitarios.accordion.glossario.termo.item119": "RES (Simplified Export Registration)",
  "utilitarios.accordion.glossario.termo.item120": "Semi trailer",
  "utilitarios.accordion.glossario.termo.item121": "SGP (General System of Preferences)",
  "utilitarios.accordion.glossario.termo.item122": "Ship Broker",
  "utilitarios.accordion.glossario.termo.item123": "Shipper (Embarcador)",
  "utilitarios.accordion.glossario.termo.item124": "Sider",
  "utilitarios.accordion.glossario.termo.item125": "SIL",
  "utilitarios.accordion.glossario.termo.item126": "Siscomex (Integrated Foreign Trade System)",
  "utilitarios.accordion.glossario.termo.item127": "Sobretaxa or Surcharge",
  "utilitarios.accordion.glossario.termo.item128": "Supply Chain Management",
  "utilitarios.accordion.glossario.termo.item129": "Tara",
  "utilitarios.accordion.glossario.termo.item130": "TEC",
  "utilitarios.accordion.glossario.termo.item131": "TEU",
  "utilitarios.accordion.glossario.termo.item132": "TKU",
  "utilitarios.accordion.glossario.termo.item133": "TMS",
  "utilitarios.accordion.glossario.termo.item134": "Transbordo ou Transhipment",
  "utilitarios.accordion.glossario.termo.item135": "Customs Transit",
  "utilitarios.accordion.glossario.termo.item136": "Intermodal Transport",
  "utilitarios.accordion.glossario.termo.item137": "Multimodal Transport",
  "utilitarios.accordion.glossario.termo.item138": "Transshipment",
  "utilitarios.accordion.glossario.termo.item139": "Trick",
  "utilitarios.accordion.glossario.termo.item140": "Truck",
  "utilitarios.accordion.glossario.termo.item141": "Unitization",
  "utilitarios.accordion.glossario.termo.item142": "Waiver",
  "utilitarios.accordion.glossario.termo.item143": "WCS",
  "utilitarios.accordion.glossario.termo.item144": "Wharfage or Taxa de atracação",
  "utilitarios.accordion.glossario.termo.item145": "WMS",
  "utilitarios.accordion.glossario.termo.item146": "Primary zone",
  "utilitarios.accordion.glossario.termo.item147": "Secondary zone",

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  "contato.title": "Contact",
  "contato.form.placeholder1": "Name",
  "contato.form.placeholder1": "E-mail",
  "contato.form.placeholder1": "Subject matter",
  "contato.form.placeholder1": "Menssage",
  "contato.form.button": "Send",
  "contato.from.message.success": "Message sent successfully!",
  "contato.from.message.error": "There was an error sending your message, please try again.",
  "contato.title.card.title1": "Address",
  "contato.title.card.text1":
    "Av. Sete de Setembro, nº 776, 5º andar, sala 501, Ed. 7 Eco Office, CEP 88.301-200, Centro -Itajaí/SC",
  "contato.title.card.title2": "E-mail",
  "contato.title.card.title3": "telephone"
};
