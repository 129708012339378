import fetch from "node-fetch"
import { callbacks } from "../components/util/callback"
import { addLocaleData, IntlProvider } from 'react-intl';
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { createContext, createActions, createCRUDActions } from "./ContextFactory";

const actions = createActions([
	...createCRUDActions(),
	"CHANGE_LANGUAGE"
]);

const initState = {
	language: undefined,
};

const reducer = async (action, data, state, cbs) => {
	switch (action) {
		case actions.CHANGE_LANGUAGE:
			return { language: data }
			break;
	}
};

export default createContext(initState, reducer, actions);
