import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
<FormattedMessage id="utilitarios.accordion.containers.table.col1" />
export default class TableContainers extends React.Component {
  render() {
    return (
      <Fragment>
        <h5>Container 20 Dry</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table class="table" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">6,08</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,352</td>
                    <td id="TableRow">5,2</td>
                    <td id="TableRow">2,39</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,34</td>
                    <td id="TableRow" />
                    <td id="TableRow">2,283</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>33</td>
                    <td id="TableRow">28,15</td>
                    <td id="TableRow">2,33</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${
                process.env.asset_path
                }/assets/images/container20dry.jpg`}
            />
          </div>
        </div>
        <h5>Container 40 Dry</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">12,192</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,59</td>
                    <td id="TableRow">12,03</td>
                    <td id="TableRow">2,39</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,34</td>
                    <td id="TableRow" />
                    <td id="TableRow">2,275</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>67,7</td>
                    <td id="TableRow">28,7</td>
                    <td id="TableRow">3,8</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${
                process.env.asset_path
                }/assets/images/container40dry.jpg`}
            />
          </div>
        </div>
        <h5>Container 40 HC</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">12,192</td>
                    <td id="TableRow">2,896</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,352</td>
                    <td id="TableRow">12,03</td>
                    <td id="TableRow">2,695</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,34</td>
                    <td id="TableRow" />
                    <td id="TableRow">2,585</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>76</td>
                    <td id="TableRow">26,48</td>
                    <td id="TableRow">4</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${
                process.env.asset_path
                }/assets/images/container40hc.jpg`}
            />
          </div>
        </div>
        <h5>Flat Rack 40</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">12,192</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,41</td>
                    <td id="TableRow">12,02</td>
                    <td id="TableRow">1,955</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>56,6</td>
                    <td id="TableRow">40,05</td>
                    <td id="TableRow">4,95</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${process.env.asset_path}/assets/images/flatrack40.jpg`}
            />
          </div>
        </div>
        <h5>Open Top 20</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">6,06</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,34</td>
                    <td id="TableRow">5,89</td>
                    <td id="TableRow">2,36</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,32</td>
                    <td id="TableRow" />
                    <td id="TableRow">2,25</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>32,6</td>
                    <td id="TableRow">21,7</td>
                    <td id="TableRow">2,3</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${process.env.asset_path}/assets/images/opentop20.jpg`}
            />
          </div>
        </div>
        <h5>Open Top 40</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">12,192</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,285</td>
                    <td id="TableRow">12,024</td>
                    <td id="TableRow">2,38</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,32</td>
                    <td id="TableRow" />
                    <td id="TableRow">2,25</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>367,33</td>
                    <td id="TableRow">26,28</td>
                    <td id="TableRow">4,2</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${process.env.asset_path}/assets/images/opentop40.jpg`}
            />
          </div>
        </div>
        <h5>Refrigerated 20</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">6,06</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,285</td>
                    <td id="TableRow">5,45</td>
                    <td id="TableRow">2,26</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,32</td>
                    <td id="TableRow" />
                    <td id="TableRow">2,25</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>28,1</td>
                    <td id="TableRow">21,8</td>
                    <td id="TableRow">3,2</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${
                process.env.asset_path
                }/assets/images/refrigerated20.jpg`}
            />
          </div>
        </div>
        <h5>Refrigerated 40</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">12,192</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,285</td>
                    <td id="TableRow">11,57</td>
                    <td id="TableRow">2,25</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,29</td>
                    <td id="TableRow" />
                    <td id="TableRow">2,265</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>67,3</td>
                    <td id="TableRow">26,28</td>
                    <td id="TableRow">4,2</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${
                process.env.asset_path
                }/assets/images/refrigerated40.jpg`}
            />
          </div>
        </div>
        <h5>Ventilated</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">6,06</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,34</td>
                    <td id="TableRow">5,9</td>
                    <td id="TableRow">2,39</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,34</td>
                    <td id="TableRow" />
                    <td id="TableRow">2,27</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>32,99</td>
                    <td id="TableRow">22,05</td>
                    <td id="TableRow">2,36</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${process.env.asset_path}/assets/images/ventilated.jpg`}
            />
          </div>
        </div>
        <h5>Plataforma 20</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">6,06</td>
                    <td id="TableRow">0,226</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,413</td>
                    <td id="TableRow">6,02</td>
                    <td id="TableRow" />
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>29</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${process.env.asset_path}/assets/images/plataforma20.jpg`}
            />
          </div>
        </div>
        <h5>Plataforma 40</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">12,192</td>
                    <td id="TableRow">0,626</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,285</td>
                    <td id="TableRow">12,15</td>
                    <td id="TableRow" />
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>67</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${process.env.asset_path}/assets/images/plataforma40.jpg`}
            />
          </div>
        </div>

        <h5>Bulk Container</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">6,058</td>
                    <td id="TableRow">2,591</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha2" />
                    </th>
                    <td id="TableRow">2,366</td>
                    <td id="TableRow">5,838</td>
                    <td id="TableRow">2,374</td>
                  </tr>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha3" />
                    </th>
                    <td id="TableRow">2,144</td>
                    <td id="TableRow" />
                    <td id="TableRow">0,500</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table2.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table2.col3" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>32,7</td>
                    <td id="TableRow">28,7</td>
                    <td id="TableRow">2,54</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${
                process.env.asset_path
                }/assets/images/bulkcontainer.jpg`}
            />
          </div>
        </div>
        <h5>Tank Container</h5>
        <div className="row" id="accordionRow">
          <div className="col-md-8">
            <div class="table-responsive">
              <table className="table table-hover" id="TableBody">
                <thead>
                  <tr>
                    <th scope="col"><FormattedMessage id="utilitarios.accordion.containers.table.col1" /></th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col2" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col3" />
                    </th>
                    <th scope="col" id="TableRow">
                      <FormattedMessage id="utilitarios.accordion.containers.table.col4" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" id="TableText">
                      <FormattedMessage id="utilitarios.accordion.containers.table.linha1" />
                    </th>
                    <td id="TableRow">2,438</td>
                    <td id="TableRow">6,06</td>
                    <td id="TableRow">2,59</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-4">
            <img
              id="ImageContainer"
              src={`${
                process.env.asset_path
                }/assets/images/tankcontainer.jpg`}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
