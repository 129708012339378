import React, { Component } from "react";
import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default class Institucional extends Component {
  render() {
    return (
      <div id="containerInstitucional">
        <div className="container">
          <div className="row">
            <section id="institucionalCards">
              <h1 style={{ textAlign: "center" }}>
                <FormattedMessage id="institucional.title" />
              </h1>
              <section id="cardRow" className="row">
                <div className="col-md-3">
                  <div id="cardInst" className="card">
                    <div className="card-body" id="cardBody">
                      <img
                        className="card-img-top"
                        src={`${process.env.asset_path}/assets/images/logoFluctusTrading.jpeg`}
                        alt="Card image cap"
                      ></img>
                      <h5 className="card-title">
                        <FormattedMessage id="institucional.card1.title" />
                      </h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <FormattedMessage id="institucional.card1.subtitle" />
                      </h6>
                      <p
                        className="card-text"
                        style={{ paddingBottom: "98px" }}
                      >
                        <FormattedMessage id="institucional.card1.text" />
                      </p>
                      <Link to="/servicos#fluctusTrading">
                        <Button className="btn btn-primary" variant="primary">
                          <i className="fas fa-plus"></i>
                          <FormattedMessage id="institucional.card.button" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div id="cardInst" className="card">
                    <div className="card-body" id="cardBody">
                      <img
                        className="card-img-top"
                        src={`${process.env.asset_path}/assets/images/logoFluctusSupplier.png`}
                        alt="Card image cap"
                      ></img>
                      <h5 className="card-title">
                        <FormattedMessage id="institucional.card2.title" />
                      </h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <FormattedMessage id="institucional.card2.subtitle" />
                      </h6>
                      <p className="card-text">
                        <FormattedMessage id="institucional.card2.text" />
                      </p>
                      <Link to="/servicos#fluctusSupplier">
                        <Button className="btn btn-primary" variant="primary">
                          <i className="fas fa-plus"></i>
                          <FormattedMessage id="institucional.card.button" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3">
									<div id="cardInst" className="card">
										<div className="card-body" id="cardBody">
											<img className="card-img-top" src={`${process.env.asset_path}/assets/images/logoFluctus3d.jpeg`} alt="Card image cap"></img>
											<h5 className="card-title"><FormattedMessage id="institucional.card3.title" /></h5>
											<h6 className="card-subtitle mb-2 text-muted"><FormattedMessage id="institucional.card3.subtitle" /></h6>
											<p className="card-text" style={{ paddingBottom: "22px" }}><FormattedMessage id="institucional.card3.text" /></p>
											<Link to="/servicos#fluctusTax">
												<Button className="btn btn-primary" variant="primary" ><i className="fas fa-plus"></i><FormattedMessage id="institucional.card.button" /></Button>
											</Link>
										</div>
									</div>
								</div> */}
                {/* <div className="col-md-3">
									<div id="cardInst" className="card">
										<div className="card-body" id="cardBody">
											<img className="card-img-top" src={`${process.env.asset_path}/assets/images/logoFluctusLog.png`} alt="Card image cap"></img>
											<h5 className="card-title"><FormattedMessage id="institucional.card4.title" /></h5>
											<h6 className="card-subtitle mb-2 text-muted"><FormattedMessage id="institucional.card4.subtitle" /></h6>
											<p className="card-text" style={{ paddingBottom: "70px" }}><FormattedMessage id="institucional.card4.text" /></p>
											<Link to="/servicos#fluctusLog">
												<Button className="btn btn-primary" variant="primary" ><i className="fas fa-plus"></i><FormattedMessage id="institucional.card.button" /></Button>
											</Link>
										</div>
									</div>
								</div> */}
                <div className="col-md-3">
                  <div id="cardInst" className="card">
                    <div className="card-body" id="cardBody">
                      <img
                        className="card-img-top"
                        src={`${process.env.asset_path}/assets/images/logoFluctusStore.png`}
                        alt="Card image cap"
                      ></img>
                      <h5 className="card-title">
                        <FormattedMessage id="institucional.card5.title" />
                      </h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <FormattedMessage id="institucional.card5.subtitle" />
                      </h6>
                      <p
                        className="card-text"
                        style={{ paddingBottom: "50px" }}
                      >
                        <FormattedMessage id="institucional.card5.text" />
                      </p>
                      <Link to="/servicos#fluctusCommerce">
                        <Button className="btn btn-primary" variant="primary">
                          <i className="fas fa-plus"></i>
                          <FormattedMessage id="institucional.card.button" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div id="cardInst" className="card">
                    <div className="card-body" id="cardBody">
                      <img
                        className="card-img-top"
                        src={`${process.env.asset_path}/assets/images/logoFluctusRelax.png`}
                        alt="Card image cap"
                      ></img>
                      <h5 className="card-title">
                        <FormattedMessage id="institucional.card6.title" />
                      </h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        <FormattedMessage id="institucional.card6.subtitle" />
                      </h6>
                      <p
                        className="card-text"
                        style={{ paddingBottom: "55px" }}
                      >
                        <FormattedMessage id="institucional.card6.text" />
                      </p>
                      <Link to="/servicos#fluctusRelax">
                        <Button className="btn btn-primary" variant="primary">
                          <i className="fas fa-plus"></i>
                          <FormattedMessage id="institucional.card.button" />{" "}
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
									<div id="cardInst" className="card">
										<div className="card-body" id="cardBody">
											<img className="card-img-top" style={{ paddingTop: 36, paddingBottom: 31 }} src={`${process.env.asset_path}/assets/images/logoFluctus3dPrint.png`} alt="Card image cap"></img>
											<h5 className="card-title"><FormattedMessage id="institucional.card7.title" /></h5>
											<h6 className="card-subtitle mb-2 text-muted"><FormattedMessage id="institucional.card7.subtitle" /></h6>
											<p className="card-text" style={{ paddingBottom: "117px" }}><FormattedMessage id="institucional.card7.text" /></p>
											<Link to="/servicos">
												<Button className="btn btn-primary" variant="primary" ><i className="fas fa-plus"></i><FormattedMessage id="institucional.card.button" /></Button>
											</Link>
										</div>
									</div>
								</div>
              </section>
            </section>
            <section id="institucionalLeft">
              <div className="col-md-7" style={{ textAlign: "justify" }}>
                <h1>
                  <FormattedMessage id="institucional.section.title" />
                </h1>
                <p>
                  <FormattedMessage id="institucional.section.text" />
                </p>
                <p>
                  <FormattedMessage id="institucional.section.text2" />
                </p>
              </div>
            </section>
            <section className="col-md-12" id="lineInst">
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                <hr />
                <br />
                <hr />
              </div>
            </section>
            <section id="institucionalRight">
              <div
                className="col-md-7 offset-md-5"
                style={{ textAlign: "justify" }}
              >
                <h1 style={{ textAlign: "right" }}>
                  <FormattedMessage id="institucional.section2.title" />
                </h1>
                <p>
                  <FormattedMessage id="institucional.section2.text" />
                </p>
              </div>
            </section>
            <section className="col-md-12" id="lineInst">
              <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                <hr />
                <br />
                <hr />
              </div>
            </section>
            <section id="institucionalLeft">
              <div className="col-md-7" style={{ textAlign: "justify" }}>
                <h1 style={{ textAlign: "left" }}>
                  <FormattedMessage id="institucional.section3.title" />
                </h1>
                <p>
                  <FormattedMessage id="institucional.section3.text" />
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
