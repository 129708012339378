import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
<FormattedMessage id="utilitarios.accordion.conversao.table1.title" />

export default class TableConversao extends React.Component {
  render() {
    return (
      <Fragment>
        <h4 id="tableH4"><FormattedMessage id="utilitarios.accordion.conversao.table1.title" /></h4>
        <table className="table table-hover" id="TableBody">
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="utilitarios.accordion.conversao.table1.col1" /></th>
              <th scope="col" id="TableRow">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.col2" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.item1" />
              </th>
              <td id="TableRow">2,54</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.item2" />
              </th>
              <td id="TableRow">0,39</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.item3" />
              </th>
              <td id="TableRow">25,40</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.item4" />
              </th>
              <td id="TableRow">0,039</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.item5" />
              </th>
              <td id="TableRow">0,31</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.item6" />
              </th>
              <td id="TableRow">3,28</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.item7" />
              </th>
              <td id="TableRow">1,61</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.item8" />
              </th>
              <td id="TableRow">0,62</td>
            </tr>
          </tbody>
        </table>
        <h4 id="tableH4"><FormattedMessage id="utilitarios.accordion.conversao.table2.title" /></h4>
        <table className="table table-hover" id="TableBody">
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="utilitarios.accordion.conversao.table1.col1" /></th>
              <th scope="col" id="TableRow">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.col2" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table2.item1" />
              </th>
              <td id="TableRow">16,39</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table2.item2" />
              </th>
              <td id="TableRow">0,06</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table2.item3" />
              </th>
              <td id="TableRow">0,03</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table2.item4" />
              </th>
              <td id="TableRow">35,32</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table2.item5" />
              </th>
              <td id="TableRow">3,79</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table2.item6" />
              </th>
              <td id="TableRow">0,26</td>
            </tr>
          </tbody>
        </table>
        <h4 id="tableH4"><FormattedMessage id="utilitarios.accordion.conversao.table3.item1" /></h4>
        <table className="table table-hover" id="TableBody">
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="utilitarios.accordion.conversao.table1.col1" /></th>
              <th scope="col" id="TableRow">
              <FormattedMessage id="utilitarios.accordion.conversao.table1.col2" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table3.item1" />
              </th>
              <td id="TableRow">0,45</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table3.item1" />
              </th>
              <td id="TableRow">2,21</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table3.item1" />
              </th>
              <td id="TableRow">1,02</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table3.item1" />
              </th>
              <td id="TableRow">0,98</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table3.item1" />
              </th>
              <td id="TableRow">0,91</td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
                <FormattedMessage id="utilitarios.accordion.conversao.table3.item1" />
              </th>
              <td id="TableRow">1,10</td>
            </tr>
          </tbody>
        </table>
        <h4 id="tableH4"><FormattedMessage id="utilitarios.accordion.conversao.table4.title" /></h4>
        <table className="table table-hover" id="TableBody">
          <thead>
            <tr>
              <th scope="col"><FormattedMessage id="utilitarios.accordion.conversao.table1.col1" /></th>
              <th scope="col" id="TableRow">
              <FormattedMessage id="utilitarios.accordion.conversao.table4.col2" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table4.item1" />
              </th>
              <td id="TableRow"><FormattedMessage id="utilitarios.accordion.conversao.table4.item1" /></td>
            </tr>
            <tr>
              <th scope="row" id="TableText">
              <FormattedMessage id="utilitarios.accordion.conversao.table4.item1" />
              </th>
              <td id="TableRow">s<FormattedMessage id="utilitarios.accordion.conversao.table4.item1" /></td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  }
}
