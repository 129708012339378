import React, { useState, useEffect } from "react";

const maps = (props, style) => {
  const { Map, TileLayer, Marker, Popup } = require("react-leaflet");
  const [position, setPosition] = useState([0, 0]);
  const [scrowWheel, setScrowWheel] = useState(false);
  useEffect(() => {
    setPosition([-26.9132614, -48.6569082]);
  }, []);

  // const lalala = [latlng.lat, latlng.lng];
  return (
    <Map
      center={position}
      zoom={16}
      zoomControl={true}
      doubleClickZoom={true}
      onClick={() => setScrowWheel(true)}
      scrollWheelZoom={scrowWheel}
      dragging={true}
      animate={true}
      style={{
        height: "400px",
        width: "100%"
      }}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          Av. Sete de Setembro, nº 776,
          <br /> 5º andar, sala 501, Ed. 7 Eco Office,
          <br /> CEP 88.301-200, Centro -Itajaí/SC <br />
        </Popup>
      </Marker>
    </Map>
  );
};

export default maps;
