import React from "react";
import { FormattedMessage } from "react-intl";
export default class Footer extends React.Component {
	render() {
		return (
			<footer id="footer">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<center>
								<img src={`${process.env.asset_path}/assets/images/logoFluctusWhite.png`} />
							</center>
						</div>
						<br />
						<div className="col-md-3" id="footerContent">
							<p>© Copyright 2023 <strong>Fluctus Group</strong>.</p>
						</div>
						<br />
						<div className="col-md-3" id="footerContent">
							<p><strong><FormattedMessage id="footer.endereco.title" /> </strong><FormattedMessage id="footer.endereco" /></p>
						</div>
						<br />
						<div className="col-md-3">
							<a target="_blank" href="http://bit.ly/2GpLBtn" className="whatsapp"><i className="fab fa-whatsapp"></i></a>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}