import React, { Component } from "react";
import Contact from "../../context/Contact.context"

import { FormattedMessage } from "react-intl";
class ContactForm extends Component {

	static contextType = Contact.Context;

	state = {
		name: "",
		email: "",
		assunto: "",
		message: "",
		msgSuccess: "",
		msgError: ""
	};

	onSubmitSendForm(e) {
		e.preventDefault();
		this.context.dispatch(
			Contact.Actions.SEND_MESSAGE_CONTACT,
			this.state,
			{
				onSuccess: {
					funct: (res) => {
						//console.log("DEU CERTO no contato")
						this.setState({ msgSuccess: "Mensagem enviada com sucesso!" })
					}
				},
				onError: {
					funct: (res, err) => {
						//console.log("DEU NADA no contato", err)
						this.setState({ msgError: "Ocorreu um erro ao enviar a sua mensagem, por favor tente novamente." })
					}
				}
			}
		);
	}
	render() {
		return (
			<form id="contactForm" onSubmit={e => this.onSubmitSendForm(e)}>
				<fieldset>
					<h1 style={{ textAlign: "center", paddingBottom: "50px" }}><FormattedMessage id="contato.title" /></h1>
					<div className="form-group">
						<div>
							<input
								id="name"
								name="name"
								type="text"
								placeholder="Nome/Name"
								className="form-control"
								value={this.state.name}
								onChange={(e) => this.setState({ "name": e.target.value })}
								required />
						</div>
					</div>
					<div className="form-group">
						<div className="input-group">
							<div className="input-group-prepend">
								<div className="input-group-text">@</div>
							</div>
							<input
								id="email"
								name="email"
								type="text"
								placeholder="E-mail"
								className="form-control"
								value={this.state.email}
								onChange={(e) => this.setState({ "email": e.target.value })}
								required />
						</div>
					</div>
					<div className="form-group">
						<div>
							<input
								id="assunto"
								name="assunto"
								type="text"
								placeholder="Assunto/Subject"
								className="form-control"
								value={this.state.assunto}
								onChange={(e) => this.setState({ "assunto": e.target.value })}
								required />
						</div>
					</div>
					<div className="form-group">
						<div>
							<textarea
								id="messageArea"
								name="message"
								placeholder="Mensagem/Message"
								rows="5"
								className="form-control"
								value={this.state.message}
								onChange={(e) => this.setState({ "message": e.target.value })}
								required />

						</div>
					</div>
					<div className="form-group">
						<div className="col-md-12 text-right" style={{ padding: "0px" }}>
							<button type="submit" className="btn btn-primary"><FormattedMessage id="contato.form.button" /></button>
						</div>
					</div>
					<div>
						{this.state.msgSuccess ? (
							<div className="alert alert-success">{this.state.msgSuccess}</div>
						) : null}
					</div>
					<div>
						{this.state.msgError ? (
							<div className="alert alert-error">{this.state.msgError}</div>
						) : null}
					</div>
				</fieldset>
			</form>
		);
	}
}

export default ContactForm;