import React from "react";
import Clock from 'react-live-clock';
import TableCotacao from "./tables/tableCotacao"
import Cotacao from "../../context/Cotacao.context";
import { FormattedMessage } from "react-intl";

var ReactFitText = require('react-fittext');

export default class FeaturedArea extends React.Component {
	render() {
		return (
			<section id="featured-area">
				<div className="container">
					<div className="row">
						<div className="col-sm-2" id="clockText">
							<h3>
								<FormattedMessage id="homepage.clock1" /><br />
								<Clock id="clockFont" format="h:mm:ssa" ticking={true} timezone={'America/Fortaleza'} interval={1000} />
							</h3>
						</div>
						<div className="col-sm-2" id="clockText">
							<h3>
								<FormattedMessage id="homepage.clock2" /><br />
								<Clock id="clockFont" format="h:mm:ssa" ticking={true} timezone={'Europe/London'} interval={1000} />
							</h3>
						</div>
						<div className="col-sm-2" id="clockText">
							<h3>
								<FormattedMessage id="homepage.clock3" /><br />
								<Clock id="clockFont" format="h:mm:ssa" ticking={true} timezone={'US/Pacific'} interval={1000} />
							</h3>
						</div>
						<div className="col-sm-2" id="clockText">
							<h3>
								<FormattedMessage id="homepage.clock4" /><br />
								<Clock id="clockFont" format="h:mm:ssa" ticking={true} timezone={'Asia/Hong_Kong'} interval={1000} />
							</h3>
						</div>
						<div className="col-sm-2" id="clockText">
							<h3>
								<FormattedMessage id="homepage.clock5" /><br />
								<Clock id="clockFont" format="h:mm:ssa" ticking={true} timezone={'Africa/Johannesburg'} interval={1000} />
							</h3>
						</div>
						<div className="col-md-4">
							<div id="homeCards" className="single-feature">
								<div className="icon">
									<i id="homeIcon" className="fab fa-black-tie"></i>
								</div>
								<div className="desc text-center">
									<h6><FormattedMessage id="homepage.card1.title" /></h6>
									<p><FormattedMessage id="homepage.card1.text" /></p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div id="homeCards" className="single-feature">
								<div className="icon">
									<i id="homeIcon" className="fas fa-handshake"></i>
								</div>
								<div className="desc text-center">
									<h6><FormattedMessage id="homepage.card2.title" /></h6>
									<p><FormattedMessage id="homepage.card2.text" /></p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div id="homeCards" className="single-feature">
								<div className="icon">
									<i id="homeIcon" className="far fa-eye"></i>
								</div>
								<div className="desc text-center">
									<h6><FormattedMessage id="homepage.card3.title" /></h6>
									<p><FormattedMessage id="homepage.card3.text" /></p>
								</div>
							</div>
						</div>
						<Cotacao.Provider>
							<TableCotacao />
						</Cotacao.Provider>
					</div>
				</div>
			</section>
		);
	}
}