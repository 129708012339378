import React from "react";
import NavLink from "../template/navlink.react";
import { Link } from 'react-router-dom'
import FlagDropdown from "../util/flagDropdown.react";
import { FormattedMessage } from "react-intl";
export default class Header extends React.Component {
  state = {
    isNavClosed: true
  };

  render() {
    return (
      <React.Fragment>
        <header id="header">
          <div className="container-fluid">
            <div id="logo" className="pull-left">
              <Link to="/"><img src={`${process.env.asset_path}/assets/images/logoFluctus.png`} className="img-fluid" /></Link>
            </div>
            <FlagDropdown />
            <nav id="nav-menu-container">
              <ul className="nav-menu">
                <NavLink to="/"><FormattedMessage id="navbar.home" /></NavLink>
                <NavLink to="/institucional"><FormattedMessage id="navbar.institucional" /></NavLink>
                <NavLink to="/servicos"><FormattedMessage id="navbar.servicos" /></NavLink>
                <NavLink to="/utilitarios"><FormattedMessage id="navbar.utilitarios" /></NavLink>
                <NavLink to="/contato"><FormattedMessage id="navbar.contato" /></NavLink>
              </ul>
            </nav>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
