import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";

export default class TableUteis extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col md 6">
              <br />
              <h5 id="titleLinksUteis">
                <FormattedMessage id="utilitarios.accordion.uteis.title1" />
              </h5>
              <br />
              <ul>
                <li>
                  <a target="_blank" href="http://www.abnt.org.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item1" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.antaq.gov.br">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item2" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.abti.com.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item3" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.antt.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item4" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.cnt.org.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item5" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.brasil.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item6" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.ibge.gov.br/home/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item7" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.receita.fazenda.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item8" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.planejamento.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item9" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.transportes.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item10" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.mdic.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col1.item11" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col md 6">
              <br />
              <h5 id="titleLinksUteis">
                <FormattedMessage id="utilitarios.accordion.uteis.title2" />
              </h5>
              <br />
              <ul>
                <li>
                  <a target="_blank" href="www.codesa.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item1" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.cdiport.com.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item2" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.portoitajai.com.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item3" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.portoitapoa.com.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item4" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.portonave.com.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item5" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.portodorecife.pe.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item6" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.portoriogrande.com.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item7" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.suape.pe.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item8" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.apsfs.sc.gov.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item9" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.portodesantos.com.br/">
                    <FormattedMessage id="utilitarios.accordion.uteis.col2.item10" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
