import React from "react";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';


class MyGoogleMaps extends React.Component {
    render() {
        const mapStyle = {
            width: '100%',
            height: '400px'
        }

        return (
            <Map google={this.props.google}
                zoom={17}
                style={mapStyle}
                initialCenter={{
                    lat: -26.9132614,
                    lng: -48.6569082
                }}
                onClick={this.onMarkerClick}>
                <Marker
                    title={'Aqui!'}
                    name={'Fluctus Group'}

                    position={{ lat: -26.9132614, lng: -48.6569082 }} />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyCoY7RDLVvOuODq-iH21AYL-e0oKiS36KI")
})(MyGoogleMaps)