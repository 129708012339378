import React, { Component } from "react";
import Newsletter from "../../context/Newsletter.context"
import { FormattedMessage } from "react-intl";

class newsletter extends Component {

  static contextType = Newsletter.Context;

  state = {
    name: "",
    email: "",
    msgSuccess: "",
    msgError: ""
  }


  onSubmitSaveForm(e) {
    e.preventDefault();
    this.context.dispatch(
      Newsletter.Actions.ADD_MEMBER_NEWSLETTER,
      this.state,
      {
        onSuccess: {
          funct: (res) => {
            // console.log("DEU CERTO")
            this.setState({ msgSuccess: "Você assinou nossa Newsletter com sucesso." })
          }
        },
        onError: {
          funct: (res, err) => {
            //console.log("DEU NADA", err)
            this.setState({ msgError: "Ocorreu um erro ao assinar a nossa Newsletter, por favor tente novamente." })
          }
        }
      }
    );
  }

  render() {
    return (
      <section id="newsletter">
        <div className="container">
          <div className="row">
            <div>
              {this.state.msgSuccess ? (
                <div className="alert alert-success">{this.state.msgSuccess}</div>
              ) : null}
            </div>
            <div>
              {this.state.msgError ? (
                <div className="alert alert-error">{this.state.msgError}</div>
              ) : null}
            </div>
            <div className="col-md-12">
              <form id="newsletterForm" onSubmit={e => this.onSubmitSaveForm(e)} >
                <h3><FormattedMessage id="newsletter.title" /></h3>
                <p className="h6" style={{ color: "dark gray" }}>
                  <FormattedMessage id="newsletter.subtitle" />
                </p>
                <div className="form-row align-items-center">
                  <div className="col-md-5 my-1">
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      id="name"
                      value={this.state.name}
                      onChange={(e) => this.setState({ "name": e.target.value })}
                      placeholder="Como você se chama?"
                      required />
                  </div>
                  <div className="col-md-6 my-1">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">@</div>
                      </div>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="email"
                        value={this.state.email}
                        onChange={(e) => this.setState({ "email": e.target.value })}
                        placeholder="Qual seu e-mail?"
                        required />
                    </div>
                  </div>
                  <div className="col-auto my-1">
                    <button type="submit" className="btn btn-primary">Enviar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default newsletter;