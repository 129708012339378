import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { Home, Institucional, Servicos, Utilitarios, Contato } from "./components/pages";
import Base from "./components/template/base.react";
import ScrollIntoView from "./components/util/ScrollIntoView.react";

import messagePortuguese from "./components/util/messagePortuguese.react";
import messageEnglish from "./components/util/messageEnglish.react";
import messageChinese from "./components/util/messageChinese.react"
import { addLocaleData, IntlProvider } from 'react-intl';
import pt from 'react-intl/locale-data/pt';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import Language from "../src/context/Language.context";

import { navigator } from "global";

addLocaleData([...pt, ...en, ...zh]);

const locale = (navigator && navigator.languages && navigator.languages[0])
  || navigator && navigator.language
  || navigator && navigator.userLanguage
  || 'pt-BR';

//substring here to en and pt and es and all

function validateLocale(locale) {
  switch (locale) {
    case "en-US":
      return messageEnglish

    case "zh-CN":
      return messageChinese

    case "pt-BR":
    case "pt":
      return messagePortuguese

    default:
      return messagePortuguese
  }
}
const App = () => {
  return (
    <Language.Provider>
      <Language.Consumer>
        {value => (
          <Fragment>
            <IntlProvider locale={locale} messages={validateLocale(
              value.state.language
              || (navigator && navigator.languages && navigator.languages[0])
              || navigator && navigator.language
              || navigator && navigator.userLanguage
              || "pt-BR")}>
              <Base>
                <Switch>
                  <ScrollIntoView>
                    <Route path="/" exact component={Home} />
                    <Route path="/institucional" component={Institucional} />
                    <Route path="/servicos" component={Servicos} />
                    <Route path="/utilitarios" component={Utilitarios} />
                    <Route path="/contato" component={Contato} />
                  </ScrollIntoView>
                </Switch>
              </Base>
            </IntlProvider>
          </Fragment>
        )}
      </Language.Consumer>
    </Language.Provider>

  );
};

export default App;

