import React from "react";
import { FormattedMessage } from "react-intl";

export default class Servicos extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="servicos">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <section id="titleServico">
                  <h1 style={{ textAlign: "center" }}><FormattedMessage id="servicos.title" /></h1>
                </section>
                <div className="servicosContent">
                  <a id="fluctusTrading">
                    <section id="ServContent">
                      <h5>
                        <i className="fas fa-cog" />
                        &nbsp;<FormattedMessage id="servicos.list.trading.title" />
                      </h5>
                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item1" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item2" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item3" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item4" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item5" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item6" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item7" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item8" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item9" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item10" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item11" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item12" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item13" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item14" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item15" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item16" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item17" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item18" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item19" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item20" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item21" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.trading.item22" />
                          </li>
                        </ul>
                      </div>
                    </section>
                  </a>
                  <a id="fluctusSearch">
                    <section id="ServContent">
                      <h5>
                        <i className="fas fa-cog" />
                        &nbsp;<FormattedMessage id="servicos.list.supplier.title" />
                      </h5>
                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.supplier.item1" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.supplier.item2" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.supplier.item3" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.supplier.item4" />
                          </li>
                        </ul>
                      </div>
                    </section>
                  </a>
                  {/* <a id="fluctusTax">
                    <section id="ServContent">
                      <h5>
                        <i className="fas fa-cog" />
                        &nbsp; <FormattedMessage id="servicos.list.tax.title" />
                      </h5>
                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.tax.item1" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.tax.item2" /></li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.tax.item3" /></li>
                        </ul>
                      </div>
                    </section>
                  </a> */}
                  <a id="fluctusLog">
                    <section id="ServContent">
                      <h5>
                        <i className="fas fa-cog" />
                        &nbsp;<FormattedMessage id="servicos.list.log.title" />
                      </h5>
                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.log.item1" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.log.item2" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.log.item3" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.log.item4" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.log.item5" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.log.item6" />
                          </li>
                        </ul>
                      </div>
                    </section>
                  </a>
                  <a id="fluctusCommerce">
                    <section id="ServContent">
                      <h5>
                        <i className="fas fa-cog" />
                        &nbsp;<FormattedMessage id="servicos.list.commerce.title" />
                      </h5>
                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.commerce.item1" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.commerce.item2" />
                          </li>
                        </ul>
                      </div>
                    </section>
                  </a>
                  <a id="fluctusRelax">
                    <section id="ServContent">
                      <h5>
                        <i className="fas fa-cog" />
                        &nbsp; <FormattedMessage id="servicos.list.relax.title" />
                      </h5>
                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.relax.item1" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.relax.item2" />
                          </li>
                        </ul>
                      </div>
                    </section>
                  </a>
                  {/* <a id="fluctusEvents">
                    <section id="ServContent">
                      <h5>
                        <i className="fas fa-cog" />
                        &nbsp; <FormattedMessage id="servicos.list.events.title" />
                      </h5>
                      <div>
                        <ul className="list-group">
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item1" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item2" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item3" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item4" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item5" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item6" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item7" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item8" />
                          </li>
                          <li className="list-group-item">
                            <FormattedMessage id="servicos.list.events.item9" />
                          </li>
                        </ul>
                      </div>
                    </section>
                  </a> */}
                </div>
                <section className="col-md-12">
                  <div style={{ marginTop: "60px", marginBottom: "60px" }}>
                    <hr />
                    <p style={{ fontStyle: "italic", textAlign: "center" }}>
                      <FormattedMessage id="servicos.paragraph" />
                    </p>
                    <hr />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
