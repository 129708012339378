import React, { Fragment } from "react";
import MyGoogleMaps from "../util/mygmaps.react";
import Maps from "../util/maps.react";
import ContactForm from "../template/contactForm.react";
import Contact from "../../context/Contact.context";
import { FormattedMessage } from "react-intl";

export default class Contato extends React.Component {
  render() {
    return (
      <Fragment>
        <Contact.Provider>
          <section id="formContato">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ContactForm />
                </div>
              </div>
            </div>
          </section>
          <section id="dadosContato">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="single-feature">
                    <div className="icon">
                      <i className="fas fa-map-pin"></i>
                    </div>
                    <div className="desc text-center">
                      <h6>
                        <FormattedMessage id="contato.title.card.title1" />
                      </h6>
                      <p style={{ textAlign: "center" }}>
                        <FormattedMessage id="contato.title.card.text1" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-feature">
                    <a href="mailto:contato@fluctusgroup.~com.br">
                      <div className="icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                    </a>
                    <div className="desc text-center">
                      <h6>
                        <FormattedMessage id="contato.title.card.title2" />
                      </h6>
                      <p>
                        <a href="mailto:contato@fluctusgroup.~com.br">contato@fluctusgroup.com.br</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-feature">
                    <a href="tel:+5547988688772">
                      <div className="icon">
                        <i className="fas fa-phone"></i>
                      </div>
                    </a>
                    <div className="desc text-center">
                      <h6>
                        <FormattedMessage id="contato.title.card.title3" />
                      </h6>
                      <p>
                        <a href="tel:+5547988688772">+55 (47) 98868-8772</a>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="mymaps">
            <div style={{ height: "400px", width: "100%", position: "relative" }}>
              {/* <MyGoogleMaps /> */}
              <Maps latitude={-26.9132614} longitude={-48.6569082}/>
            </div>
          </section>
        </Contact.Provider>
      </Fragment>
    );
  }
}
